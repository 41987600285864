import Axios from 'axios';
import { buildString } from '../core/helper';

const apiUrl = process.env.REACT_APP_JOB_BOARD_API;
const apiCatalogUrl = process.env.REACT_APP_CATALOG_API;

const mainContext = 'talent';
const module = 'skills';

const headerLanguage = {
    headers: {
        "Accept-Language": "es-MX"
    }
}

export const getSkills = talentId => Axios.get(`${apiUrl}${mainContext}/${talentId}/${module}/`, headerLanguage);

export const saveAll = (talentId, request, userId) => Axios.put(`${apiUrl}${mainContext}/${talentId}/${module}/save?userId=${userId}`, request, headerLanguage);

export const getSkillsTag = (filter, filterType, visibilityFilter, transFilter, page=0, size=10) => {
    //console.log('getSkillsTag', 'filter', filter, 'filterType', filterType, 'visibilityFilter', visibilityFilter, 'transFilter', transFilter);
    const filterParam = filter ? `&name=${filter}` : '';
    const publicParam = visibilityFilter.length === 1 ? `&isPublic=${ visibilityFilter.find( v => v.id === 1) ? 'true' : 'false' }` : '';
    const transParam = transFilter.length === 1 ? `&containsEnglishTranslation=${ transFilter.find( v => v.id === 1) ? 'true' : 'false' }` : '';
    let filterCategoryId = '';
    if( filterType.length > 0 ) {
        const valueString = [];
        filterType.forEach( v => valueString.push(v.id) );
        const categories = buildString(valueString).replaceAll(' ', '');
        filterCategoryId = `&categoryId=${categories}`;
    }
    return Axios.get(`${apiCatalogUrl}skills/all?page=${page}&size=${size}&sort=name&direction=ASC${filterCategoryId}${filterParam}${publicParam}${transParam}`, headerLanguage);
}

export const getSkillTag = skillTagId => Axios.get(`${apiCatalogUrl}skill/${skillTagId}`, headerLanguage);

export const getSkillTagByName = skillTagName => Axios.get(`${apiCatalogUrl}skill/findByName?wholeWord=true&name=${skillTagName.replaceAll(' ', '%20')}`, headerLanguage);

export const persistSkillTag = (userId, skillTag) => Axios.post(`${apiCatalogUrl}user/${userId}/skills/save`, skillTag, headerLanguage);

export const deleteSkillTag = (userId, skillTagId, forceDeletion) => {
    return Axios.delete(`${apiCatalogUrl}user/${userId}/skills/${skillTagId}/delete?forceDeletion=${forceDeletion}`, headerLanguage);
}

export const deleteSkillTagValidation = skillTagId => {
    return Axios.get(`${apiCatalogUrl}skills/${skillTagId}/delete-validation`, headerLanguage);
}

export const getSkillProfiles = (isPublic = true, paginated = false, sorter='name', direction='ASC') => {
    return Axios.get(`${apiCatalogUrl}skillsprofile/all?isPublic=${isPublic}&paginated=${paginated}&sortBy=${sorter}&sortDir=${direction}`, headerLanguage);
}