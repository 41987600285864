import PropTypes from 'prop-types';
import { useContext, useState } from "react";
import styles from './searcher.module.scss';
import dropDownIcon from '../../../../assets/icons/arrow_drop.svg'
import closeIcon from '../../../../assets/icons/close_small.svg';
import checkboxIcon from '../../../../assets/icons/checkbox_uncheck.svg'
import checkboxCheckedIcon from '../../../../assets/icons/checkbox_checked.svg'
import { buildString } from '../../../core/helper';
import { AppContext } from '../../../pages/candidates/context/AppProvider';

export const SelectMultiple = ({
	name,
	value,
	setValue,
	options,
	placeholder,
	onChange,
	optRigth
}) => {
	const appContext = useContext(AppContext);
	const [showOptions, setShowOptions] = useState(false);

    const onSelectOption = o => {
		appContext.setCurrentPage(0);
        onChange(o, value, setValue);
        setShowOptions(false);
    }

	const cleanOptions = () => {
		appContext.setCurrentPage(0);
		setValue([]);
	}

	const handleOnBlur = () => setTimeout(() => setShowOptions(false), 100);

    const renderOptions = () => (
		showOptions && options.length > 0 && (
			<div className={ styles.container_select_options } style={ optRigth ? { right: '0', top: '48px' } : { left: '0', top: '55px' }}	>
				{
					options.map((o, index) => (
						<div key={index} onClick={ () => onSelectOption(o) } className={ styles.container_select_options_option }>
							<img src={ value.find( v => v.id === o.id ) ? checkboxCheckedIcon : checkboxIcon } alt="" />
							{ o.optionName }
						</div>
					))
				}
			</div>
		)
	);

	const buildLabel = () => {
		const valueSorted = value.sort((a, b) => a.id - b.id).slice(0,2);
		const valueString = [];
		valueSorted.forEach( v => valueString.push(v.optionName) );
		return `${placeholder}: ${buildString(valueString)}`;
	}

	return (
        <div className={ `${styles.container_select} ${value.length > 0 ? styles.container_selected : undefined}` } onClick = { () => setShowOptions(!showOptions) } tabIndex="1" onBlur={ handleOnBlur }>
			<div className={ styles.container_select_label }>
				{ value.length > 0 && <img className={ styles.container_selected_close } src={ closeIcon } alt="" onClick = { cleanOptions } /> }
				{ value.length === 0 ? placeholder : buildLabel() }
				{ value.length > 2 && <div className={ styles.counter }>{`+${value.length-2}`}</div> }
			</div>
            <img className={ `${value.length > 0 ? styles.container_selected_arrow : undefined} ${ showOptions ? styles.container_selected_rotate : undefined }` } src={ dropDownIcon } alt="" />
            { renderOptions() }
        </div>
	);
};

SelectMultiple.propTypes = {
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	value: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			optionName: PropTypes.string.isRequired,
		})
	).isRequired,
	setValue: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			optionName: PropTypes.string.isRequired,
		}),
	).isRequired,
	optRigth: PropTypes.bool
};