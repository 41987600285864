import PropTypes from 'prop-types';
import styles from './modal_base.module.scss'
import { useContext } from 'react';
import { AppContext } from '../../pages/candidates/context/AppProvider';

export const ModalDelete = ({
    title,
    subtitle,
    paragrahp,
    onClick,
    onClickCancel
}) => {

    const appContext = useContext(AppContext);

    return (
        <div className={ styles.modal_delete }>
            <p className={ styles.title } >{ `${ appContext.t('button.delete') } “${title}”` }</p>
            <br/><label className={ styles.subtitle }>{ subtitle }</label><br/>
            { paragrahp && <><label className={ styles.subtitle }>{ paragrahp }</label><br/></> }
            <br/>
            <div className={ styles.buttons }>
                <button className={ styles.cancel_button } onClick={ onClickCancel }>{ appContext.t('button.cancel') }</button>
                <button onClick={ onClick }>{ appContext.t('button.delete') }</button>
            </div>
        </div>
    )
}

ModalDelete.propTypes = {
    title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired
};