import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../../../../components/inputs/input.module.scss'
import { ReactComponent as IconClose } from '../../../../../assets/icons/close_small.svg';
import { ReactComponent as IconAdd } from '../../../../../assets/icons/add_plus.svg';
import { selectEmtpy } from '../../../../core/hardcode';
import { borderError, removeAccents } from '../../../../core/helper';

export const InputSearcher = ({
    id,
	value = selectEmtpy,
    placeholder = '',
	onChange,
    options,
    optionsSelecteds = [],
    error,
    index,
    categoryId,
    tagId,
    onSkillEditPage,
    width=100
}) => {

    const [selectOptions, setSelectOptions] = useState([]);
	const [filterOptions, setFilterOptions] = useState([]);
	const [showOptions, setShowOptions] = useState(false);
    const [editMode, setEditMode] = useState(value.id === 0 && value.description === '');
    const [isSelected, setIsSelected] = useState(false);
    const [valueStored, setValueStored] = useState();

    const sorter = (a, b) => {
        const textA = removeAccents(a.optionName).toUpperCase();
        const textB = removeAccents(b.optionName).toUpperCase();
        const result = (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        return result;
    }

	useEffect(() => {
        let filterOptTmp = options.filter( o => o && !optionsSelecteds.includes(o.id));
        if (options) {
            filterOptTmp = filterOptTmp.sort((a,b) => sorter(a, b));
            setSelectOptions(filterOptTmp);
            if(value.description === '') {
                setFilterOptions(filterOptTmp);
            }
        }
        setIsSelected((value.id !== 0 && value.description && value.description !== '') ? true : false);
	}, [value, options, optionsSelecteds]);

    const onType = ({ target }) => {
        const newSkill = { id: 0, description: target.value};
        setValueStored(newSkill);
        onChange(tagId, categoryId, newSkill, false);
        if (target.value === '') {
            setShowOptions(false);
			setFilterOptions(selectOptions);
			return;
		}
		const newOptions = selectOptions.filter(({ optionName }) => (
			optionName.toLowerCase().includes(target.value.toLowerCase())
		));
        setShowOptions(newOptions && newOptions.length > 0);
        setFilterOptions(newOptions);
	};

    const onSelectOption = ({id, optionName}) => {
		const skillSelected = { id: id, description: optionName};
        onChange(tagId, categoryId, skillSelected, true);
        setIsSelected(true);
        setShowOptions(false);
        setEditMode(false);
	};

	const onCleanOption = () => {
        setTimeout(() => {
            onChange(tagId, categoryId, selectEmtpy, false);
            // setFilterOptions(options);
            // setShowOptions(false);
            document.getElementById(`${categoryId}_${index}`).focus();
        }, 100);
	};
    
    const onEdit = () => {
        setShowOptions(true);
        if ( isSelected ) {
            setValueStored({ ...value, 'categoryId': categoryId });
            setEditMode(true);
            setIsSelected(false);
        }
    }

    const onBlur = () => {
        if( value && value.id !== 0 && valueStored ) {
            setIsSelected(true);
        }
        setShowOptions(false);
        setEditMode(false);
    }

    const onFocus = () => {
        setEditMode(true);
    }

    const handleOnBlur = () => {
        setTimeout(() => setShowOptions(false), 100);
    }

    const renderOptions = () => (
		showOptions && filterOptions.length > 0 && (
			<div className={ styles.select_container }>
				<ul>
					{
						filterOptions.map((opt, index) => (
                            <li key={ index } value={ opt.id } onMouseDown={ () => onSelectOption(opt) }>
                                { opt.optionName }
                            </li>
                        ))
					}
				</ul>
			</div>
		)
	);


    const renderNewOption = () => (
		editMode && value.description.length > 0 && !options.find( o => o.optionName === value.description ) && (
			<div className={ styles.input_select_new } onMouseDown={ () => onSkillEditPage(value, categoryId) }>
                <input type='text' readOnly style={ { cursor: 'pointer' } } value={ value.description } />
                { <IconAdd className={ styles.select_icon_arrow } /> }
            </div>
		)
	);

    return (
        <div
            className={ styles.input_container }
            style={{ width: `${width}%` }}
            onClick={ onEdit }
            onBlur={ handleOnBlur }>
            <input className={ isSelected ? styles.input_searcher_with_text : styles.input_searcher }
                style={ borderError(error) }
                autoComplete='off'
                readOnly={ !editMode }
                type="text"
                value={ value.description }
                placeholder={ placeholder }
                onChange={ onType }
                onBlur={ onBlur }
                onFocus={ onFocus }
                id={ id }
            />
            { value && value.description !== '' && <IconClose className={ styles.select_icon_close_white } onClick={ onCleanOption } /> }
            { renderNewOption() }
            { renderOptions() }
        </div>
    )
}

InputSearcher.propTypes = {
    id: PropTypes.string.isRequired,
	value: PropTypes.shape({
        id: PropTypes.number,
        description: PropTypes.string,
        categoryId: PropTypes.node
    }).isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.string,
			]),
			optionName: PropTypes.string.isRequired,
		}),
	).isRequired,
    optionsSelecteds: PropTypes.arrayOf(PropTypes.number),
    error: PropTypes.string,
    index: PropTypes.number.isRequired,
    categoryId: PropTypes.number.isRequired,
    tagId: PropTypes.number.isRequired,
    onSkillEditPage: PropTypes.func.isRequired,
    width: PropTypes.number
};