import Axios from "axios";

const apuUrl = process.env.REACT_APP_CUSTOMERVIEW_API;

export const getAllUsers = (filter, roleId, clientFilter, paginated, page=0, size=10, sorter='userregistration.name', direction='asc') => {
    const paginatedParam = paginated ? `&paginated=true` : '';
    const filterParam = filter ? `&filter=${filter}` : '';
    const roleParam = roleId ? `&roleId=${roleId}` : '';
    let clientParam = '';
    if( clientFilter ) {
        clientFilter.forEach( c => clientParam += `&clientId=${c.id}` )
    }
    return Axios.get(`${apuUrl}user/all?page=${page}&size=${size}&sortBy=${sorter}&direction=${direction}${paginatedParam}${filterParam}${roleParam}${clientParam}`);
}

// export const getBoardLink = userId => Axios.get(`${apuUrl}user/${userId}/getBoardLink`);