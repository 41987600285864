import styles from './notification.module.scss';
import checkIcon from '../../../assets/icons/checkicon_white.svg';
import wifiErrorIcon from '../../../assets/icons/wifi_error.svg';
import { useContext } from 'react';
import { AppContext } from '../../pages/candidates/context/AppProvider';

const Notification = (props) => {

    const appContext = useContext(AppContext);

    return(
        <div className={ styles.notification } 
            style={ { display: props.data.isDisplay ? 'block' : 'none' } }>
            <div className={ props.data.notificationStyle }>
                <img src={ props.data.isErrorConection ? wifiErrorIcon : (props.data.icon ? props.data.icon : checkIcon) } alt="" />
                <p>{ props.data.message ? props.data.message : appContext.t('error.network') }</p>
            </div>
        </div>
    )
}
export default Notification