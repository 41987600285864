import { useContext, useState } from 'react';
import styles from '../../../../components/modal/modal_base.module.scss';
import closeIcon from '../../../../../assets/icons/close.svg';
import { Checkbox } from '../../../../components/inputs/Checkbox';
import { InputText } from '../../../../components/inputs/InputText';
import { MONTHS, selectEmtpy, yearsHardCode, yearsOrderAsc, yearsOrderDesc } from '../../../../core/hardcode';
import { CandidateContext } from '../../context';
import { validateIsSelected, validateNotNull, validateSelectRangeDate } from '../../../../core/validators';
import { serializeDateString, sorterDate } from '../../../../core/helper';
import { SelectRange } from '../../../../components/inputs/SelectRange';
import { AppContext } from '../../context/AppProvider';
import Select, { SelectSortType } from '../../../../components/inputs/Select';

export const CertificationModal = ({
  catStudyLevel
}) => {
  const appContext = useContext(AppContext);

  const { 
    certification, 
    setCertification,
    certifications, 
    setCertifications 
  } = useContext( CandidateContext );

  const [errors, setErrors] = useState(false);
  const [certificateNumber, setCertificateNumber] = useState(certification.certificateNumber);

  const validationFields = {
    name: [validateNotNull],
    school: [validateNotNull],
    fiMes: [validateIsSelected],
    fiAnio: [validateIsSelected],
    ftMes: [validateIsSelected],
    ftAnio: [validateIsSelected],
    fvMes: [validateIsSelected],
    fvAnio: [validateIsSelected],
    type: [validateIsSelected]
  }

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const [catMonths, setCatMonths] = useState(MONTHS);

  const validateRequest = () => {
    // creando el objeto de errores
    const errorObject = {};
    var helper;
    for(const field in validationFields) {
        for (const validator in validationFields[field]) {
            helper = validationFields[field][validator](certification[field])
            if (helper) {
                errorObject[field] = helper
            }
        }
    }
    // validación startDate y endDate
    if( !certification.stillStudy 
        && !validateIsSelected(certification.fiMes) && !validateIsSelected(certification.fiAnio)
        && !validateIsSelected(certification.ftMes) && !validateIsSelected(certification.ftAnio) ) {
        const start = {
            yearId: certification.fiAnio.id,
            monthId: certification.fiMes.id
        }
        const end = {
            yearId: certification.ftAnio.id,
            monthId: certification.ftMes.id
        }
        const compareDates = validateSelectRangeDate(start, end, yearsOrderAsc);
        if( compareDates ) {
            errorObject['ftMes'] = compareDates
            errorObject['ftAnio'] = compareDates
        }
    } else if( certification.stillStudy) {
        delete errorObject.ftMes;
        delete errorObject.ftAnio;
    }
    // si no hay vigencia se elimina el error de fecha de vigencia del objeto de errores
    if(certification.validity) {
        delete errorObject.fvMes;
        delete errorObject.fvAnio;
    }
    // validando que fecha de inicio sea mayor que la fecha de término
    if(!certification.stillStudy && Date.parse(certification.fiAnio.description + "-" + certification.fiMes.id + "-01") > Date.parse(certification.ftAnio.description + "-" + certification.ftMes.id + "-01")) {
      errorObject.fiAnio = [' ']
      errorObject.fiMes = [' ']
      errorObject.ftAnio = [' ']
      errorObject.ftMes = [appContext.t('validator.daterange')]
  }

    return errorObject
  }

  const handleOnClickAdd = () => {
      const validationErrors = validateRequest();
      if (Object.keys(validationErrors).length === 0) {
          const startDate = (certification.fiMes && certification.fiAnio && certification.fiMes.id !== 0 && certification.fiAnio.id !== 0)
              ? serializeDateString(certification.fiMes.id, certification.fiAnio.description) : null;
          const endDate = (certification.ftMes && certification.ftAnio && certification.ftMes.id !== 0 && certification.ftAnio.id !== 0)
              ? serializeDateString(certification.ftMes.id, certification.ftAnio.description) : null;
          const validityDate = (certification.fvMes && certification.fvAnio && certification.fvMes.id !== 0 && certification.fvAnio.id !== 0)
              ? serializeDateString(certification.fvMes.id, certification.fvAnio.description) : null;
          const certificationTmp = {
            ...certification,
            'startDate': startDate,
            'endDate': endDate,
            'validityDate': validityDate,
            'certificateNumber': certificateNumber,
            'startDateParsed': `${certification.fiMes.description} ${certification.fiAnio.description}`,
            'endDateParsed': (!certification.stillStudy ? `${certification.ftMes.description} ${certification.ftAnio.description}` : appContext.t('noData.inProg')),
            'validityParsed': (!certification.validity ? `${certification.fvMes.description} ${certification.fvAnio.description}` : appContext.t('noData.notvalid'))
          };
          setCertification(certificationTmp);
          const weExist = certifications[certification.index];
          let certificationsTmp = [...certifications];
          if( weExist ) {
              certificationsTmp[certification.index] = certificationTmp;
          } else {
              certificationsTmp.unshift(certificationTmp)
          }
          const certificationsCurrents = certificationsTmp.filter( ce => !ce.validityDate );
          let certificationsLast = certificationsTmp.filter( ce => ce.validityDate );
          certificationsLast = [ ...certificationsLast.sort((a, b) => sorterDate(a.ftAnio.id, a.ftMes.id, b.ftAnio.id , b.ftMes.id, yearsOrderAsc)) ];
          certificationsTmp = [ ...certificationsCurrents ]
          certificationsTmp = [ ...certificationsTmp, ...certificationsLast ]
          setCertifications(certificationsTmp);
          appContext.setModalFlag(false)
      } else {
          setErrors(validationErrors)
      }
  }

  const updateCertification = (property, value) => {
    setCertification({ ...certification, [property]: value});

    if(property === "fiAnio") {
      if(value.description === currentYear ) {
          if(certification.fiMes.id === 0) {
              setCatMonths(MONTHS.slice(0, currentMonth + 1));
          } else {
              if(certification.fiMes.id > currentMonth + 1) {
                  setCertification({
                    ...certification,
                    fiMes: selectEmtpy,
                    fiAnio: selectEmtpy,
                  })

                  setErrors({
                    ...errors,
                    fiMes: [appContext.t('validator.date')],
                    fiAnio: [appContext.t('validator.date')]
                  })
              }
          }
      } else {
          setCatMonths(MONTHS);
      }
    }
  }

  const handleCheckstudyYet = (property, value) => {
      if( value ) {
        if (property === "validity") {
          setCertification({
            ...certification,
            [property]: value,
            fvMes: selectEmtpy,
            fvAnio: selectEmtpy,
          });
        } else {
          setCertification({
              ...certification,
              [property]: value,
              ftMes: selectEmtpy,
              ftAnio: selectEmtpy,
          });
        }
      } else {
        setCertification({ ...certification, [property]: value});
      }
  }

  const updateStudyLevel = (property, value) => {
    setCertification({ ...certification, [property]: value, [`${property}Id`]: value.id, [`${property}Name`]: value.description});
  }

  return (
    <div className={ styles.modal_container }>
        <div className={ styles.close }>
            <img src={ closeIcon } alt="" onClick={() => appContext.setModalFlag(false)} />
        </div>
        <p className={ styles.title } >{ `${certification.courseId && certification.courseId > 0 ? appContext.t('button.edit') : appContext.t('button.add')} ${ appContext.t('candidate.resume.courses.titleModal') }` }</p>
        <div className={ styles.form_container }>
            <div className={ styles.form_wrapper }>
                <div className={ styles.form_columns }>
                    <InputText
                      cssContainer={ styles.form_column }
                      name="name"
                      label={ `${ appContext.t('candidate.resume.courses.name') }*` }
                      value={ certification.name }
                      maxLength={ 150 }
                      onChange={ updateCertification } 
                      error={ Object.keys(errors).includes("name") ? errors.name[0] : '' }
                    />
                    <InputText
                      cssContainer={ styles.form_column }
                      name="school"
                      label={ `${ appContext.t('candidate.resume.courses.school') }*` }
                      value={ certification.school }
                      maxLength={ 200 }
                      onChange={ updateCertification } 
                      error={ Object.keys(errors).includes("school") ? errors.school[0] : '' }
                    />
                </div>
                <div className={ styles.form_columns }>
                  <div className={ styles.form_column }>
                    <div className={ styles.custom_select }>
                        <label>{ appContext.t('candidate.resume.courses.study') }*</label>
                        <Select
                            name="type"
                            placeholder={ appContext.t('candidate.resume.academic.chooseStudy') }
                            value={ certification.type }
                            onChange={ updateStudyLevel }
                            options={ catStudyLevel }
                            error= { Object.keys(errors).includes("type") ? errors.type[0] : '' }
                            sort={ SelectSortType.ALPHA }
                        />
                        {
                            Object.keys(errors).includes("type") && (
                                <p className={ styles.error_message }>
                                    { errors.type ? `${errors.type[0]}*` : '' }
                                </p>
                            )
                        }
                    </div>
                  </div>
                  <div className={ styles.form_column}>
                    <div className={ styles.custom_input }>
                      <label>{ appContext.t('candidate.resume.courses.certNum') } (opcional)</label>
                      <input type="text" onChange={(event) => setCertificateNumber(event.target.value)  } value={ certificateNumber }/>
                    </div>
                  </div>
                </div>
                <p className={ styles.form_subtitle }>{ appContext.t('candidate.resume.courses.period') }</p>
                <div className={ styles.form_columns }>
                    <div className={ styles.form_column }>
                        <label>{ appContext.t('candidate.resume.labelStartDate') }*</label>
                        <SelectRange 
                          prop1={{
                            name: 'fiMes',
                            onChange: updateCertification,
                            options: catMonths,
                            placeholder: appContext.t('candidate.resume.labelMonth'),
                            value: certification.fiMes,
                            error: Object.keys(errors).includes("fiMes") ? errors.fiMes[0] : ''
                          }}
                          prop2={{
                            name: 'fiAnio',
                            onChange: updateCertification,
                            options: yearsHardCode(yearsOrderDesc),
                            placeholder: appContext.t('candidate.resume.labelYear'),
                            value: certification.fiAnio,
                            error: Object.keys(errors).includes("fiAnio") ? errors.fiAnio[0] : ''
                          }}
                        />
                    </div>
                    <div className={ styles.form_column }>
                        <label>{ appContext.t('candidate.resume.labelEndDate') }*</label>
                        <SelectRange 
                          prop1={{
                            name: 'ftMes',
                            onChange: updateCertification,
                            options: MONTHS,
                            placeholder: appContext.t('candidate.resume.labelMonth'),
                            value: certification.ftMes,
                            error: (!certification.stillStudy && Object.keys(errors).includes("ftMes")) ? errors.ftMes[0] : ''
                          }}
                          prop2={{
                            name: 'ftAnio',
                            onChange: updateCertification,
                            options: yearsHardCode(yearsOrderDesc),
                            placeholder: appContext.t('candidate.resume.labelYear'),
                            value: certification.ftAnio,
                            error: (!certification.stillStudy && Object.keys(errors).includes("ftAnio") ) ? errors.ftAnio[0]  : ''
                          }}
                          disabled={ certification.stillStudy }
                        />
                        <Checkbox
                          cssLabel = { styles.subtitle } 
                          name="stillStudy"
                          label = { appContext.t('candidate.resume.courses.stillStudy') }
                          value={ certification.stillStudy }
                          onClick={ handleCheckstudyYet } />
                    </div>
                </div>
                <p className={ styles.form_subtitle }>{ appContext.t('candidate.resume.courses.validity') }</p>
                <div className={ styles.form_columns }>
                  <div className={ styles.form_column }>
                      <label>{ appContext.t('candidate.resume.courses.effective') }*</label>
                      <SelectRange 
                        prop1={{
                          name: 'fvMes',
                          onChange: updateCertification,
                          options: MONTHS,
                          placeholder: appContext.t('candidate.resume.labelMonth'),
                          value: certification.fvMes,
                          error: (!certification.validity && Object.keys(errors).includes("fvMes")) ? errors.fvMes[0] : ''
                        }}
                        prop2={{
                          name: 'fvAnio',
                          onChange: updateCertification,
                          options: yearsHardCode(yearsOrderAsc),
                          placeholder: appContext.t('candidate.resume.labelYear'),
                          value: certification.fvAnio,
                          error: (!certification.validity && Object.keys(errors).includes("fvAnio") ) ? errors.fvAnio[0]  : ''
                        }}
                        disabled={ certification.validity }
                      />
                      <Checkbox
                        cssLabel = { styles.subtitle } 
                        name="validity"
                        label = { appContext.t('noData.notvalid') }
                        value={ certification.validity }
                        onClick={ handleCheckstudyYet } />
                  </div>
                </div>
            </div>
            <div className={ styles.buttons }>
              <button className={ styles.cancel_button + " " + styles.mobile } onClick={() => appContext.setModalFlag(false)}>{ appContext.t('button.cancel') }</button>
              <button onClick={() => handleOnClickAdd()}>{ certification.courseId && certification.courseId > 0 ? appContext.t('button.save') : appContext.t('button.add')}</button>
            </div>
        </div>
    </div>
  )
}
