import { useContext } from 'react';
import { AppContext } from '../../context/AppProvider';
import styles from '../../../../components/header/header.module.scss';

export const SkillModal = ({
    title,
    message,
    labelBtnConfirm,
    skillTag,
    confirmFuction = null,
    deleteFunction = null
}) => {

    const appContext = useContext(AppContext);

    const onModalOption = skillTag => {
        appContext.setModalFlag(false);
        if (skillTag) {
            if( confirmFuction ) {
                confirmFuction(skillTag);
            } else if( deleteFunction ) {
                deleteFunction(skillTag);
            }
        }
    }

    const renderDeleteNotAllowed = () => (
        <div className={ styles.error_modal }>
            <div className={ styles.wrapper }>
                <div className={ styles.content_title }>
                    <p className={ styles.title }>{ appContext.t('candidate.resume.skills.delete.modalErrorTitle') }</p>
                </div>
                <p>{ `${skillTag.name} ${appContext.t('candidate.resume.skills.delete.modalErrorMsg1')} ` }<b>{ `${appContext.t('candidate.resume.skills.delete.modalErrorMsg2')} ${message}` }</b></p>
                <div className={ styles.content_buttons_centred }>
                    <div className={ styles.buttons }>
                        <button className={ styles.confirm } onClick={ () => onModalOption(null) }>{ appContext.t('button.closeMsg') }</button>
                    </div>
                </div>
            </div>
        </div>
    )

    const renderTwoOptions = () => (
        <div className={ styles.error_modal }>
            <div className={ styles.wrapper }>
                <div className={ styles.content_title }>
                    <p className={ styles.title }>{ title }</p>
                </div>
                { message }
                <div className={ styles.content_buttons }>
                    <div className={ styles.buttons }>
                        <button className={ styles.cancel } onClick={ () => onModalOption(null) }>{ appContext.t('button.cancel') }</button>
                        <button className={ styles.confirm } onClick={ () => onModalOption(skillTag) }>{ labelBtnConfirm }</button>
                    </div>
                </div>
            </div>
        </div>
    )

    return labelBtnConfirm ? renderTwoOptions() : renderDeleteNotAllowed()
}
