import { useContext, useState } from "react";
import { AppContext } from "../../context/AppProvider";
import { CandidateContext } from "../../context";
import styles from '../../candidateInfo.module.scss';
import stylesM from '../../../../components/modal/modal_base.module.scss';
import Select, { SelectSortType } from "../../../../components/inputs/Select";
import { InputText } from "../../../../components/inputs/InputText";
import { validateDateFormat, validateRangeDates } from "../../../../core/validators";

const validationFields = {
    startDate: [validateDateFormat],
    endDate: [validateDateFormat],
    hireDate: [validateDateFormat]
}

export const validateAssigmentRequest = (assigment) => {
    const errorObject = {};
    var helper;
    // validating fields
    for(const field in validationFields) {
        for (const validator in validationFields[field]) {
            helper = validationFields[field][validator](assigment[field])
            if (helper) {
                errorObject[field] = helper
            }
        }
    }
    helper = validateRangeDates(assigment.startDate, assigment.endDate);
    if( helper ) {
        errorObject['endDate'] = helper
    }
    return errorObject
}

export const Assigment = ({
    resumeViewFlag,
    renderBackButton,
    catClient,
    catUser
}) => {

    const appContext = useContext(AppContext);

    const {
        assigment,
        setAssigment,
        assigmentErrors
    } = useContext( CandidateContext );

    const [optionsCliSelecteds, setOptionsCliSelecteds] = useState([]);
    const [optLeadAreaSelecteds, setOptLeadAreaSelecteds] = useState([]);
    const [optLeaderSelecteds, setOptLeaderSelecteds] = useState([]);

    const onChangeInput = (property, value) => {
        setAssigment({ ...assigment, [property]: value});
    }

    const onChangeSelect = (index, property, value, preValue) => {
        setAssigment({
            ...assigment,
            [property]: value && value.id ? value : { id: 0, description: value.description },
            [`${property}Id`]: value && value.id ? value.id : 0,
            [`${property}Value`]: value && value.description ? value.description : ''
        });
        if (value && value.id && value.id !== 0) {
            let optSelectedTmp = []
            switch (property) {
                case 'client':
                    optSelectedTmp = optionsCliSelecteds.filter( o => o !== preValue.id );
                    setOptionsCliSelecteds([...optSelectedTmp, value.id])
                    break;
                case 'leaderArea':
                    optSelectedTmp = optLeadAreaSelecteds.filter( o => o !== preValue.id );
                    setOptLeadAreaSelecteds([...optSelectedTmp, value.id])
                    break;
                case 'leader':
                    optSelectedTmp = optLeaderSelecteds.filter( o => o !== preValue.id );
                    setOptLeaderSelecteds([...optSelectedTmp, value.id])
                    break;
                default:
                    break;
            }
        }
    }

    const removedCliFromIcon = (id) => {
        const indexHelper = optionsCliSelecteds.indexOf(id)
        setOptionsCliSelecteds([...optionsCliSelecteds.slice(0, indexHelper), ...optionsCliSelecteds.slice(indexHelper + 1)])
    }

    const removedLeadAreaFromIcon = (id) => {
        const indexHelper = optLeadAreaSelecteds.indexOf(id)
        setOptLeadAreaSelecteds([...optLeadAreaSelecteds.slice(0, indexHelper), ...optLeadAreaSelecteds.slice(indexHelper + 1)])
    }

    const removedLeaderFromIcon = (id) => {
        const indexHelper = optLeaderSelecteds.indexOf(id)
        setOptLeaderSelecteds([...optLeaderSelecteds.slice(0, indexHelper), ...optLeaderSelecteds.slice(indexHelper + 1)])
    }

    const renderTitle = () => (<p className={ styles.title }>{ appContext.t('candidate.resume.assignment.title') }</p>);

    return (
        <div className={ styles.form_container }>
            { appContext.isDesktopOrLaptop ? renderTitle() : ( resumeViewFlag ? renderTitle() : renderBackButton(appContext.t('candidate.resume.assignment.title'))) }
            <div className={ styles.form_wrapper }>
                <div className={ styles.form_columns }>
                    <div className={ styles.form_column }>
                        <div className={ stylesM.custom_select }>
                            <label>{ appContext.t('candidate.resume.assignment.labelCustomer') }</label>
                            <Select
                                name="client"
                                index={ 1 }
                                placeholder={ appContext.t('candidate.resume.assignment.placeholderCustomer') }
                                value={ assigment.client }
                                sercheable
                                onChange={ onChangeSelect } 
                                options={ catClient ? catClient : [] }
                                optionsSelecteds={ optionsCliSelecteds }
                                removedFromIcon={ removedCliFromIcon }
                                error={ Object.keys(assigmentErrors).includes("client") ? assigmentErrors.client[0] : '' }
                                sort={ SelectSortType.ALPHA }
                            />
                            {
                                Object.keys(assigmentErrors).includes("client") && (
                                    <p className={ styles.error_message_font }>
                                        { assigmentErrors.client ? assigmentErrors.client[0] : '' }
                                    </p>
                                )
                            }
                        </div>
                    </div>
                    <InputText
                        cssContainer={ styles.form_column }
                        name={ 'area' }
                        label={ `${appContext.t('candidate.resume.assignment.labelArea')}` }
                        value={ assigment.area || "" }
                        onChange={ onChangeInput }
                        error={ Object.keys(assigmentErrors).includes("area") ? assigmentErrors.area[0] : '' }
                        marginError={ Object.keys(assigmentErrors).includes("area") && !Object.keys(assigmentErrors).includes("area") }
                    />
                </div>
                <div className={ `${styles.form_row} ${styles.paragraph}` }>
                    <label className={ styles.label_blue }>{ appContext.t('candidate.resume.assignment.labelLeaderTitle') }</label>
                    <label className={ styles.subtitle }>{ appContext.t('candidate.resume.assignment.labelLeaderDesc') }</label>
                </div>
                <div className={ styles.form_columns }>
                    <div className={ styles.form_column }>
                        <div className={ stylesM.custom_select }>
                            <label>{ appContext.t('candidate.resume.assignment.labelLeader') }</label>
                            <Select
                                name="leaderArea"
                                index={ 2 }
                                placeholder={ appContext.t('candidate.resume.assignment.placeholderLeader') }
                                value={ assigment.leaderArea }
                                sercheable
                                onChange={ onChangeSelect } 
                                options={ catUser ? catUser : [] }
                                optionsSelecteds={ optLeadAreaSelecteds }
                                removedFromIcon={ removedLeadAreaFromIcon }
                                error={ Object.keys(assigmentErrors).includes("leaderArea") ? assigmentErrors.leaderArea[0] : '' }
                                sort={ SelectSortType.ALPHA }
                            />
                            {
                                Object.keys(assigmentErrors).includes("leaderArea") && (
                                    <p className={ styles.error_message_font }>
                                        { assigmentErrors.leaderArea ? assigmentErrors.leaderArea[0] : '' }
                                    </p>
                                )
                            }
                        </div>
                    </div>
                    <div className={ styles.form_column }>
                        <div className={ stylesM.custom_select }>
                            <label>{ appContext.t('candidate.resume.assignment.labelBoss') }</label>
                            <Select
                                name="leader"
                                index={ 3 }
                                placeholder={ appContext.t('candidate.resume.assignment.placeholderLeader') }
                                value={ assigment.leader }
                                sercheable
                                onChange={ onChangeSelect } 
                                options={ catUser ? catUser : [] }
                                optionsSelecteds={ optLeaderSelecteds }
                                removedFromIcon={ removedLeaderFromIcon }
                                error={ Object.keys(assigmentErrors).includes("leader") ? assigmentErrors.leader[0] : '' }
                                sort={ SelectSortType.ALPHA }
                            />
                            {
                                Object.keys(assigmentErrors).includes("leader") && (
                                    <p className={ styles.error_message_font }>
                                        { assigmentErrors.leader ? assigmentErrors.leader[0] : '' }
                                    </p>
                                )
                            }
                        </div>
                    </div>
                </div>
                <p className={ `${styles.form_section_title} ${styles.label_blue}` }>{ appContext.t('candidate.resume.assignment.labelRange') }</p>
                <div className={ styles.form_columns }>
                    <InputText
                        cssContainer={ styles.form_column }
                        name={ 'startDate' }
                        placeholder={ appContext.t('labelDate') }
                        label={ `${appContext.t('candidate.resume.labelStartDate')}` }
                        value={ assigment.startDate || "" }
                        onChange={ onChangeInput }
                        modeDate
                        error={ Object.keys(assigmentErrors).includes("startDate") ? assigmentErrors.startDate[0] : '' }
                        marginError={ Object.keys(assigmentErrors).includes("endDate") && !Object.keys(assigmentErrors).includes("startDate") }
                    />
                    <InputText
                        cssContainer={ styles.form_column }
                        name={ 'endDate' }
                        placeholder={ appContext.t('labelDate') }
                        label={ `${appContext.t('candidate.resume.labelEndDate')}` }
                        value={ assigment.endDate || "" }
                        onChange={ onChangeInput }
                        modeDate
                        error={ Object.keys(assigmentErrors).includes("endDate") ? assigmentErrors.endDate[0] : '' }
                        marginError={ Object.keys(assigmentErrors).includes("startDate") && !Object.keys(assigmentErrors).includes("endDate") }
                    />
                </div>
                <div className={ styles.form_columns }>
                    <InputText
                        cssContainer={ styles.form_column }
                        name={ 'hireDate' }
                        placeholder={ appContext.t('labelDate') }
                        label={ `${appContext.t('candidate.resume.assignment.labelHired')}` }
                        value={ assigment.hireDate || "" }
                        onChange={ onChangeInput }
                        modeDate
                        error={ Object.keys(assigmentErrors).includes("hireDate") ? assigmentErrors.hireDate[0] : '' }
                    />
                </div>
            </div>
        </div>
    )
}
