import {
    useContext,
    useEffect,
    useState
} from 'react';
import styles from '../../candidateInfo.module.scss';
import addIcon from '../../../../../assets/icons/add_circle.svg';
import trashIcon from '../../../../../assets/icons/trash.svg';
import infoIcon from '../../../../../assets/icons/info_icon.svg';
import Select, { SelectSortType } from '../../../../components/inputs/Select';
import { CandidateContext } from '../../context';
import { setLanguageSelecValues } from '../../../../core/helper';
import { getLanguages } from '../../../../api/languageClient';
import { validateIsSelected } from '../../../../core/validators';
import { AppContext } from '../../context/AppProvider';

export const validateLanguageRequest = languages => {
    const errorObject = {};
    if( languages.length > 0 ) {
        languages.forEach( (l, index) => {
            const langValidation = validateIsSelected(l.language);
            if( langValidation ) {
                errorObject[`language_${index}`] = langValidation
            }
            const levelValidation = validateIsSelected(l.langLevel);
            if( levelValidation ) {
                errorObject[`langLevel_${index}`] = levelValidation
            }
        })
    }
    return errorObject;
}

const Languages = ({
    resumeViewFlag,
    renderBackButton,
    setStepIndex,
    catLanguages,
    catLevels
}) => {
    const appContext = useContext(AppContext)

    const {
        language,
        cleanLanguage,
        languages, 
        setLanguages,
        setLanguagesCache,
        languageErrors,
        setLanguageErrors
    } = useContext( CandidateContext );

    const [classPadded, setClassPadded] = useState(styles.padded);
    const [optionsSelecteds, setOptionsSelecteds] = useState([]);

    const loadLanguages = () => {
        appContext.showLoading(true, appContext.t('loading'), styles.no_scroll);
        getLanguages(appContext.talent.id).then((response) => {
            if( response.status === 200 && response.data && response.data.code === 201 && response.data.result && Array.isArray(response.data.result) ) {
                appContext.showLoading(false, '', styles.no_scroll);
                let languagesTmp = [];
                let languagesSelected = [];
                response.data.result.forEach( (language, index) => {
                    const laWithSelecValues = setLanguageSelecValues(language, index, catLanguages, catLevels);
                    languagesTmp.push(laWithSelecValues);
                    languagesSelected.push(language.languageId);
                })
                setOptionsSelecteds(languagesSelected)
                setLanguages(languagesTmp);
                setLanguagesCache(languagesTmp);
            }
        }).catch(error => {
            appContext.showLoading(false, '', styles.no_scroll);
            console.log(error)
        })
    }

    const onClickAdd = () => {
        const errorValidations = validateLanguageRequest(languages);
        setLanguageErrors(errorValidations);
        const errors = Object.keys(errorValidations);
        const errorLang = errors.filter( w => w.includes('language_')).length > 0;
        const errorLevel = errors.filter( w => w.includes('langLevel_')).length > 0;
        if ( !(errorLang || errorLevel) && languages.length < catLanguages.length ) {
            const index = languages.length;
            cleanLanguage();
            const levelBasic = catLevels.find( c => c.id === 1 );
            const tmpLanguage = {
                ...language,
                'index': index,
                'langLevel': { id: levelBasic.id, description: levelBasic.optionName }
            }
            setLanguages([...languages, tmpLanguage]);
            setClassPadded( styles.padded_short );
        }
    }

    const handleOnClickRemove = index => {
        setOptionsSelecteds([...optionsSelecteds.slice(0, index), ...optionsSelecteds.slice(index + 1)])
        const languagesTmp = [ ...languages ];
        languagesTmp.splice(index, 1);
        setLanguages( languagesTmp );
        setLanguageErrors(validateLanguageRequest(languagesTmp));
    }

    const updateLanguage = (index, property, value, preValue) => {
        const indexReal = index - 1;
        const propertyReal = property.replace(`_${indexReal}`, '');
        const language = {
            ...languages.find( l => l.index === indexReal ),
            [propertyReal]: value && value.id ? value : { id: 0, description: value.description },
            [`${propertyReal}Id`]: value.id ? value.id : 0
        }
        // revisando los lenguajes ya seleccionados
        if (propertyReal === 'language' && value && value.id && value.id !== 0) {
            const optSelectedTmp = optionsSelecteds.filter( o => o !== preValue.id );
            setOptionsSelecteds([...optSelectedTmp, value.id])
        }
        let languagesTmp = [ ...languages ];
        languagesTmp[indexReal] = language;
        setLanguages(languagesTmp);
        setLanguageErrors(validateLanguageRequest(languagesTmp));
    }

    const removedFromIcon = (id) => {
        const indexHelper = optionsSelecteds.indexOf(id)
        setOptionsSelecteds([...optionsSelecteds.slice(0, indexHelper), ...optionsSelecteds.slice(indexHelper + 1)])
    }

    useEffect(() => {
        loadLanguages();
    }, []);

    const renderTitles = index => languages && languages.length > 0 && (
        <div className={ `${styles.form_columns_line} ${ index === 0 ? styles.top_padded : styles.top_padded_short }` }>
            <div className={ styles.form_column }>
                <label>{ `${appContext.t('candidate.resume.lang.labelLang')} ${index+1}*`}</label>
            </div>
            <div className={ styles.form_columns_line_2 }>
                <label>{ index === 0 ? `${appContext.t('candidate.resume.lang.labelLevel')}*` : ''}</label>
                { index === 0 && (<img src={ infoIcon } alt="" width="24" height="24" onClick={ () => setStepIndex(7) } />) }
            </div>
        </div>
    )

    const renderLanguages = () => (
        languages && languages.length > 0 && languages.map((la, index) => {
            return (
                <div key={index}>
                    { renderTitles( index) }
                    <div className={ styles.form_columns_line }>
                        <div className={ styles.form_column }>
                            <Select
                                name={`language_${index}`}
                                placeholder={ appContext.t('candidate.resume.lang.labelLangPh') }
                                value={ la.language }
                                sercheable
                                onChange={ updateLanguage } 
                                options={ catLanguages ? catLanguages : [] }
                                optionsSelecteds={ optionsSelecteds }
                                removedFromIcon={ removedFromIcon }
                                index={ index+1 }
                                error= { Object.keys(languageErrors).includes(`language_${index}`) ? languageErrors[`language_${index}`][0] : '' }
                                sort={ SelectSortType.LANG }
                            />
                            {
                                Object.keys(languageErrors).includes(`language_${index}`) && (
                                    <p className={ styles.error_message_font }>{ languageErrors[`language_${index}`][0] }</p>
                                )
                            }
                        </div>
                        <div className={ styles.form_column }>
                            <div className={ styles.form_column_line }>
                                <Select 
                                    name={`langLevel_${index}`}
                                    placeholder={ appContext.t('candidate.resume.lang.labelLevelPh') }
                                    value={ la.langLevel }
                                    onChange={ updateLanguage } 
                                    options={ catLevels ? catLevels : [] }
                                    index={ index+1 }
                                    error= { Object.keys(languageErrors).includes(`langLevel_${index}`) ? languageErrors[`langLevel_${index}`][0] : '' }
                                />
                                <div className={ styles.action_container }>
                                    <img className={ styles.trash } src={ trashIcon } alt="" width={ 24 } height={ 24 } onClick={ () => handleOnClickRemove(index) } />
                                </div>
                            </div>
                            {
                                Object.keys(languageErrors).includes(`langLevel_${index}`) && (
                                    <p className={ styles.error_message_font }>{ languageErrors[`langLevel_${index}`][0] }</p>
                                )
                            }
                        </div>
                    </div>
                    
                </div>
            )}
        )
    );

    const renderTitle = () => (<p className={ styles.title }>{ appContext.t('candidate.resume.lang.title') }</p>);

    return (
        <div className={ styles.form_container }>
            { appContext.isDesktopOrLaptop ? renderTitle() : ( resumeViewFlag ? renderTitle() : renderBackButton(appContext.t('candidate.resume.lang.title'))) }
            { renderLanguages() }
            <div className={ styles.form_wrapper }>
                <div className={ `${styles.form_row} ${classPadded}` }>
                    <button className={ styles.add_info } onClick={ () => onClickAdd() }>
                        { appContext.t('candidate.resume.lang.labelAdd') }
                        <img src={ addIcon } alt="" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Languages;