import { useContext } from 'react';
import styles from '../../candidateInfo.module.scss';
import { AppContext } from '../../context/AppProvider';
import editIcon from '../../../../../assets/icons/orange_pencil.svg';
import { defaultStringEmpty } from '../../../../core/talent-module/helper';

export const AssigmentResume = ({
    personalInfo,
    updateView
}) => {

    const appContext = useContext(AppContext);

    return (
        <div className={ styles.section }>
            <div className={ styles.section_header}>
                <div className={ styles.section_title }>
                    <div className={ styles.decorator }></div>
                    { appContext.t('candidate.resume.assignment.title') }
                </div>
                <div className={ styles.button_container }>
                    <div className={ styles.vertical_separator }></div>
                    <button className={ styles.add_info } onClick={() => updateView(19, appContext.t('candidate.resume.assignment.title'))}>
                        { appContext.t('candidate.resume.edit') }
                        <img src={ editIcon } alt="" />
                    </button>
                </div>
            </div>
            <div className={ `${styles.section_header} ${styles.section_subtitle}` }>
                <label>{appContext.t('candidate.resume.assignment.labelCustomer')}: </label>{ defaultStringEmpty(personalInfo.client) }
            </div>
            <div className={ styles.section_header }>
                <label>{appContext.t('candidate.resume.assignment.labelArea')}: </label>{ defaultStringEmpty(personalInfo.positionArea) }
            </div>
            <div className={ styles.section_header }>
                <label>{appContext.t('candidate.resume.assignment.labelBoss')}: </label>{ defaultStringEmpty(personalInfo.leaderBoss) }
            </div>
            <div className={ styles.section_header }>
                <label>{appContext.t('candidate.resume.assignment.labelLeader')}: </label>{ defaultStringEmpty(personalInfo.currentLeader) }
            </div>
            <div className={ styles.section_header }>
                <label>{appContext.t('candidate.resume.assignment.labelRange')}: </label>{ `${personalInfo.assignmentStartDate}${ personalInfo.assignmentEndDate ? ' - ' + personalInfo.assignmentEndDate : ''}` }
            </div>
            <div className={ styles.section_header }>
                <label>{appContext.t('candidate.resume.assignment.labelHired')}: </label>{ personalInfo.hireDate }
            </div>
        </div>
    )
}
