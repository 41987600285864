import PropTypes from 'prop-types';
import { borderError, isNum } from '../../../../core/helper';

export const InputPercentage = ({
    id,
	value = 0,
    onChange,
    error,
    index,
    categoryId
}) => {

    const onPercentageType = ({ target }) => {
        const percValue = target.value;
        if(percValue === '') {
            onChange(0, index, categoryId);
        } else if( isNum(percValue) && ( percValue >= 0 && percValue <= 100 ) ) {
            onChange(Number(percValue), index, categoryId);
        }
    }

    const onKeyDownPerc = ({ key }) => {
        const percentage = value && value.length > 0 ? isNum(value) : 0;
        if( key === 'Backspace' && percentage > 10 && percentage <= 100 ) {
            onChange(percentage, index, categoryId);
        } else if( key === 'Backspace' && (percentage > 0 && percentage <= 10) ) {
            onChange(0, index, categoryId);
        }
    }

    return (
        <input id={ id }
            style={ error ? { ...borderError(error), textAlign: 'center', width: '85px' } : { textAlign: 'center', width: '85px' }} 
            autoComplete='off'
            value={ value }
            placeholder='0'
            onChange={ onPercentageType }
            onKeyDown={ onKeyDownPerc }
        />
    )
}

InputPercentage.propTypes = {
    id: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    index: PropTypes.number.isRequired,
    categoryId: PropTypes.number.isRequired
};