import { useState } from "react"
import styles from './input.module.scss';

import {ReactComponent as HidePassIcon} from '../../../assets/icons/hide_password.svg';
import {ReactComponent as ShowPassIcon} from '../../../assets/icons/view_password.svg';
import { PasswordSuggestion } from "./PasswordSuggestions";

export const InputPassword = ({
    isShort,
    name,
    label,
    value,
    msgError,
    onChange,
    requestFlag,
    bottom,
    arrowBottom
}) => {

    const [passwordVisibilityFlag, setPasswordVisibilityFlag] = useState(true);
    const [showSuggestion, setShowSuggestion] = useState(false);

    return (
        <div className={ `${styles.form_field} ${ isShort ? styles.short : ''}` }>
            <label>{ label }</label>
            <div className={ `${styles.password_container} ${msgError ? styles.error : undefined}` }>
                <input type={ passwordVisibilityFlag ? "password" : "text" } name={ name } onChange={ onChange }
                    onFocus = { () => setShowSuggestion(true) } onBlur = { () => setShowSuggestion(false) }  />
                <button className={ styles.button_show_pswd } type='button' onClick={(() => setPasswordVisibilityFlag(!passwordVisibilityFlag))}>
                    { passwordVisibilityFlag ? <HidePassIcon /> : <ShowPassIcon /> } 
                </button>
            </div>
            { msgError && <p className={ styles.error_message }>{ msgError }</p> }
            { showSuggestion && 
                <PasswordSuggestion
                    value={ value }
                    requestFlag={ requestFlag }
                    bottom={bottom}
                    arrowBottom={ arrowBottom }
                /> 
            }
        </div>
    )
}
