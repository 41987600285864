export const types = {
    setPersonalInfo: '[Candidate] setPersonalInfo',
    setVisibility: '[Candidate] setVisibility',
    setAssigment: '[Candidate]: setAssigment',
    setAssigmentCache: '[Candidate]: setAssigmentCache',
    setAssigmentErrors: '[Candidate]: setAssigmentErrors',
    setWorkExperience: '[Candidate] setWorkExperience',
    setAcademic: '[Candidate] setAcademic',
    setCertification: '[Candidate] setCertification',
    setSkill: '[Candidate] setSkill',
    setCompetition: '[Candidate]: setCompetition',
    setCompetitionCache: '[Candidate]: setCompetitionCache',
    setCompetitionErrors: '[Candidate]: setCompetitionErrors',
    setVisibilities: '[Candidate] setVisibilities',
    setVisibilitiesCache: '[Candidate] setVisibilitiesCache',
    setVisibilitiesErrors: '[Candidate] setVisibilitiesErrors',
    setWorkExperiences: '[Candidate] setWorkExperiences',
    setWorkExperiencesCache: '[Candidate] setWorkExperiencesCache',
    setAcademics: '[Candidate] setAcademics',
    setAcademicsCache: '[Candidate] setAcademicsCache',
    setCertifications: '[Candidate] setCertifications',
    setCertificationsCache: '[Candidate] setCertificationsCache',
    setLanguages: '[Candidate] setLanguages',
    setLanguagesCache: '[Candidate] setLanguagesCache',
    setLanguageErrors: '[Candidate] setLanguageErrors',
    setSkills: '[Candidate] setSkills',
    setSkillErrors: '[Candidate]: setSkillErrors',
    setSkillsCache: '[Candidate]: setSkillsCache'
}