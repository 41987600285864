import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Searcher } from '../../../../components/talent-module/searcher/Searcher';
import styles from '../../talentInfo.module.scss';
import { getTalents } from '../../../../api/talentInfoClient';
import { defaultStringEmpty, getFilterDate } from '../../../../core/talent-module/helper';
import talentPhotoDefault from '../../../../../assets/img/talent_image_default.svg';
import donwloadIcon from '../../../../../assets/icons/file.svg';
import reloadIcon from '../../../../../assets/icons/reload.svg';
import talentStarReview from '../../../../../assets/icons/talent_score_star.svg';
import { Pagination } from '../../../../components/talent-module/pagination/Pagination';
import { Loading } from '../../../../components/talent-module/loading/Loading';
import CandidateInfo from '../../../candidates/candidateInfo';
import { AppContext } from '../../../candidates/context/AppProvider';
import { objEquals, onClickPreviewCV } from '../../../../core/helper';
import { getCatalog, getClientCatalog, getLangLevelCatalog } from '../../../../api/catalogClient';
import { SelectMultiple } from '../../../../components/talent-module/searcher/SelectMultiple';
import { Select } from '../../../../components/talent-module/searcher/Select';
import moment from 'moment';
import { InfoMessage, InfoMessageType } from '../misc/InfoMessage';

export const TalentList = ({
  candidateMode = false
}) => {

  const appContext = useContext(AppContext);

  const [modal, setModal] = useState();
  const [talents, setTalents] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [showReload, setShowReload] = useState(false);
  const [catStatus, setCatStatus] = useState([]);
  const [catLevels, setCatLevels] = useState([]);
  const [catTranslate, setCatTranslate] = useState([]);
  const [catClient, setCatClient] = useState([]);

  const loadCatTypes = () => {
    getCatalog(1000010009, 0 ,200, '&sort=id&direction=ASC').then((response) => {
        if( response.status === 200 && response.data && response.data.content && Array.isArray(response.data.content) ) {
            let statusTmp = [];
            const content = candidateMode ? response.data.content.filter( l => !l.valueItem.includes('Talento') ) : response.data.content.filter( l => l.valueItem.includes('Talento') );
            content.map( l => {
                const status = { id: l.catalogItemId, optionName: l.valueItem }
                statusTmp.push(status);
            })
            setCatStatus(statusTmp);
        }
    }).catch((response) => {
        console.log(response);
    })
  }

  const loadCatTranslate = () => {
    getCatalog(1000020054, 0 ,200, '&sort=valueItem&direction=ASC').then((response) => {
        if( response.status === 200 && response.data && response.data.content && Array.isArray(response.data.content) ) {
            let languagesTmp = [];
            response.data.content.map( l => {
                languagesTmp.push({ id: Number(l.valueItem), optionName: l.description });
            })
            setCatTranslate(languagesTmp);
        }
    }).catch(error => {
        console.log(error)
    })
  }

  const loadCatLangLevels = () => {
    getLangLevelCatalog().then((response) => {
        if( response.status === 200 && response.data && response.data.code === 202 && response.data.result && Array.isArray(response.data.result) ) {
            let langLevelsTmp = [];
            response.data.result.map( l => {
                let desc = l.description ? l.description : '';
                desc = desc.substring(0, desc.indexOf(':'));
                desc = `${l.name}${desc ? ' - ' : ''}${desc}`;
                const language = { id: l.id, optionName: desc}
                langLevelsTmp.push(language);
            })
            setCatLevels(langLevelsTmp);
        }
    }).catch(error => {
        console.log(error)
    })
  }

  const loadCatClient = () => {
    getClientCatalog().then( response => {
      if( response.status === 200 && response.data && response.data.code === 200 && response.data.result && Array.isArray(response.data.result) ) {
        let clientsTmp = [];
        response.data.result.map( l => clientsTmp.push({ id: l.id, optionName: l.name}))
        setCatClient(clientsTmp);
    }
    }).catch( error => {
      console.log(error);
    })
  }

  const hasFilterActive = (total, filter, statusFilter, dateFilter, langFilter, levelFilter, clientFilter, required = false) => {
    return required ? (total > 0 || filter !== '' || statusFilter.length > 0 || dateFilter || langFilter || levelFilter || clientFilter.length > 0) 
    : (total === 0 || filter !== '' || statusFilter.length > 0 || dateFilter || langFilter || levelFilter || clientFilter.length > 0);
  }

  const fetchTalents = (filter, currentPage, statusFilter, dateFilter, langFilter, levelFilter, clientFilter) => {
    setTotal(0);
    setTalents([]);
    setModal(<Loading />);
    getTalents(candidateMode, filter, currentPage, 10, statusFilter, dateFilter, langFilter, levelFilter, clientFilter).then((response) => {
      if( response.status === 200 && response.data && response.data.result && response.data.result.content && Array.isArray(response.data.result.content) ) {
        setTalents(response.data.result.content)
        setTotalPages(response.data.result.totalPages);
        setTotal(response.data.result.totalElements);
        setShowReload(hasFilterActive(response.data.result.totalElements, filter, statusFilter, dateFilter, langFilter, levelFilter, clientFilter));
        setModal(null);
      } else {
        setModal(null);
        setTalents([]);
        setTotalPages(0);
      }
    }).catch(error => {
      setTalents([]);
      setTotalPages(0);
      setModal(null);
    })
  }

  useEffect(() => {
    loadCatTypes();
    loadCatTranslate();
    loadCatLangLevels();
    loadCatClient();
  }, [candidateMode]);

  useEffect(() => {
    fetchTalents(appContext.filter, appContext.currentPage, appContext.statusFilter, appContext.dateFilter, appContext.langFilter, appContext.levelFilter, appContext.clientFilter);
  }, [appContext.filter, appContext.currentPage, appContext.statusFilter, appContext.dateFilter, appContext.langFilter, appContext.levelFilter, , appContext.clientFilter]);

  const onClickDetail = talent => {
    appContext.setTalent( talent );
    appContext.setMenuOptSelected(null);
    appContext.contentScrollUp();
    appContext.setMenuNavigation([...appContext.menuNavigation, { label: defaultStringEmpty(`${talent.firstName} ${talent.lastName}`)}]);
    appContext.setHomeContent(<CandidateInfo candidateMode={ candidateMode } />)
  }
  
  const onKeyDownSearch = ({key}) => {
    const isEmpty = key === 'Backspace' && appContext.filter.length === 1;
    if ( key === 'Enter' || isEmpty ) {
      setShowReload(!isEmpty);
      onClickSearch(isEmpty ? '' : appContext.filter, appContext.statusFilter, appContext.dateFilter, appContext.langFilter, appContext.levelFilter, appContext.clientFilter);
    }
  }

  const onChangeSearch = ({target}) => {
    appContext.setFilter(target.value);
  }
  
  const onClickSearch = (filter, statusFilter, dateFilter, langFilter, levelFilter, clientFilter) => {
    appContext.setCurrentPage(0);
    fetchTalents(filter, 0, statusFilter, dateFilter, langFilter, levelFilter, clientFilter);
  }

  const reload = () => {
    setShowReload(false);
    appContext.cleanFilter();
    onClickSearch('', [], null, null, null, []);
  }

  const onClickBackPage = () => {
    if( appContext.currentPage > 0 ) {
      appContext.setCurrentPage(appContext.currentPage - 1);
      fetchTalents(appContext.filter, appContext.currentPage - 1, appContext.statusFilter, appContext.dateFilter, appContext.langFilter, appContext.levelFilter, appContext.clientFilter);
    }
  }

  const onClickForwardPage = cP => {
    if( appContext.currentPage + cP < totalPages ) {
      appContext.setCurrentPage(appContext.currentPage + 1);
      fetchTalents(appContext.filter, appContext.currentPage + 1, appContext.statusFilter, appContext.dateFilter, appContext.langFilter, appContext.levelFilter, appContext.clientFilter);
    }
  }

  const onClickSelectFilter = (option, value, setValue) => {
    const newValue = value.find( v => objEquals(v,option) ) ? value.filter( v => v.id !== option.id ) : [ ...value, option ];
    setValue(newValue);
  }

  const renderSelectsFilter = () => (
    <div className={ styles.container_list_sel }>
      <SelectMultiple
        name='statusFilter'
        value={ appContext.statusFilter }
        setValue={ appContext.setStatusFilter }
        options = { catStatus }
        placeholder = { appContext.t('candidate.list.headers.status') }
        onChange = { onClickSelectFilter }
      />
      { candidateMode &&
        <Select
          name='dateFilter'
          value={ appContext.dateFilter }
          setValue={ appContext.setDateFilter }
          options = { getFilterDate() }
          placeholder = { appContext.t('candidate.list.labelDateFilter') }
        />
      }
      { candidateMode &&
        <Select
          name='langFilter'
          value = { appContext.langFilter }
          setValue={ appContext.setLangFilter }
          options = { catTranslate }
          placeholder = { appContext.t('candidate.resume.lang.labelLang') }
        />
      }
      { candidateMode &&
        <Select
          name='levelFilter'
          value = { appContext.levelFilter }
          setValue={ appContext.setLevelFilter }
          options = { catLevels }
          placeholder = { appContext.t('candidate.list.labelLevelFilter') }
        />
      }
      { !candidateMode &&
        <SelectMultiple
          name='clientFilter'
          value = { appContext.clientFilter }
          setValue={ appContext.setClientFilter }
          options = { catClient }
          placeholder = { appContext.t('candidate.list.labelClientFilter') }
          onChange = { onClickSelectFilter }
        />
      }
    </div>
  )

  const renderNotFound = () => {
    let title = '';
    let subtitles = [];
    let cssParagraph = '';
    let type = null;
    if( talents.length === 0 && appContext.filter === '' && appContext.statusFilter.length === 0 && !appContext.dateFilter && !appContext.langFilter && !appContext.levelFilter ) {
      title = appContext.t(`candidate.notFound.title${ candidateMode ? 'Candidate' : '' }`);
      subtitles.push(appContext.t('candidate.notFound.subtitle'));
      subtitles.push(appContext.t('candidate.notFound.detail'));
      type = InfoMessageType.notFound;
      cssParagraph = styles.pharagrap_multiple;
    } else if (talents.length === 0 && (appContext.filter !== '' || appContext.statusFilter.length > 0 || appContext.dateFilter || appContext.langFilter || appContext.levelFilter )) {
      title = appContext.t('notMatch.title');
      subtitles.push(appContext.t('notMatch.subtitle'));
      type = InfoMessageType.notMatch;
      cssParagraph = styles.pharagrap_simple;
    }
    return <InfoMessage title={ title } subtitles={ subtitles } type={ type } cssParagraph={ cssParagraph } shadow />
  }

  const renderItems = () => talents.map( (talent, index) => (
    <tr key={ index }>
      <td className={ `${styles.first_child_border} ${styles.textright}` } onClick={ () => onClickDetail(talent) }>
        <div className={ styles.photo_padding }>
          <img className={ styles.photo } src={ talent.imgProfile ? `${talent.imgProfile}?${new Date().getTime()}` : talentPhotoDefault } alt='' />
        </div>
      </td>
      <td onClick={ () => onClickDetail(talent) }>
        <div className={ styles.td_column }>
          <label className={ styles.name }>{ defaultStringEmpty(`${talent.firstName} ${talent.lastName}`) }</label>
          <label className={ styles.font_secondary }>{ defaultStringEmpty(`${talent.position}${talent.jobSeniority ? ' ' + talent.jobSeniority : ''}`) }</label>
        </div>
      </td>
      <td onClick={ () => onClickDetail(talent) }>
        <div className={ styles.td_column }>
          <label>{ defaultStringEmpty(talent.statusName) }</label>
          &nbsp;
        </div>
      </td>
      { !candidateMode &&
        <td className={ styles.textcenter }>
          <div className={ styles.review_container }>
            <div>{talent.rating}</div>
            <img src={ talentStarReview } alt='' />
          </div>
        </td>
      }
      { !candidateMode &&
        <td>
          <div className={ styles.td_column }>
            <label>{ defaultStringEmpty(talent.client ? talent.client.name : '') }</label>
            &nbsp;
          </div>
        </td>
      }
      { !candidateMode &&
        <td className={ `${styles.last_child_border} ${styles.textleft}` }>
          <div className={ styles.td_column }>
            <label>{ defaultStringEmpty(talent.currentLeader) }</label>
            &nbsp;
          </div>
        </td>
      }
      { candidateMode &&
        <td className={ styles.textcenter } onClick={ () => onClickDetail(talent) }>
          <div className={ styles.td_column }>
            {talent.languages.map( (l, index) => <label key={index} className={ styles.textleft }>{`${l.languageName} ${l.langLevelName}`}</label> )}
          </div>
        </td>
      }
      { candidateMode &&
        <td onClick={ () => onClickDetail(talent) }>
          <div className={ styles.td_column }>
            <label>{ moment(talent.updatedAt).format('DD/MM/YYYY') }</label>
          </div>
        </td>
      }
      { candidateMode &&
        <td className={ styles.textcenter } onClick={ () => onClickPreviewCV(talent.id) } >
          <img src={ donwloadIcon } alt='' />
        </td>
      }
      { candidateMode && <td className={ styles.last_child_border } onClick={ () => onClickDetail(talent) }></td> }
    </tr>
  ) );

  const renderTable = () => (
    <table>
      <thead>
        <tr>
          <th className={ styles.first_child_border }></th>
          <th className={ styles.textleft }>{ `${appContext.t('candidate.list.headers.name')} ${appContext.t('candidate.list.headers.' + ( candidateMode ? 'profile' : 'position' ))}` }</th>
          <th className={ styles.textleft }>{appContext.t('candidate.list.headers.status')}</th>
          { !candidateMode && <th className={ styles.textcenter }>{appContext.t('candidate.list.headers.qualification')}</th> }
          { !candidateMode && <th className={ styles.textleft }>{appContext.t('candidate.list.headers.client')}</th> }
          { !candidateMode && <th className={ `${styles.last_child_border} ${styles.textleft}` }>{appContext.t('candidate.list.headers.leader')}</th> }
          { candidateMode && <th className={ styles.textleft }>{appContext.t('candidate.list.headers.lang')}</th> }
          { candidateMode && <th className={ styles.textleft }>{appContext.t('candidate.list.headers.date')}</th> }
          { candidateMode && <th className={ styles.textcenter }>{appContext.t('candidate.list.headers.cv')}</th> }
          { candidateMode && <th className={ styles.last_child_border }></th> }
        </tr>
      </thead>
      <tbody>
        { renderItems() }
      </tbody>
    </table>
  )

  const buildLabelResult = () => {
    let entityString = appContext.t('candidate.list.labelTotal');
    if( total === 1 && entityString.endsWith('s') ) {
      entityString = entityString.substring(0, entityString.length-1);
    }
    const filterActiveReq = hasFilterActive(total, appContext.filter, appContext.statusFilter, appContext.dateFilter, appContext.langFilter, appContext.levelFilter, appContext.clientFilter, true);
    const hasFilter = appContext.filter !== '' || appContext.statusFilter.length > 0 || appContext.dateFilter || appContext.langFilter || appContext.levelFilter;
    let complementString = (candidateMode && filterActiveReq) || (!candidateMode && hasFilter) ? appContext.t('candidate.list.labelNoFound') : ('');
    complementString = complementString.replace('s', total === 1 ? '' : 's');
    return `${entityString} ${complementString}`;
  }

  return (
    <div className={ styles.container } >
      <div className={ styles.wrapper }>
        <label className={ styles.title }>{ appContext.t(`candidate.list.title${ candidateMode ? 'Candidate' : '' }`) }</label>
        <div className={ styles.container_list_btn }>
          <div className={ styles.btn_label }>
            <div className={ styles.width_40 }>
              <Searcher 
                name={'filter'} 
                value={ appContext.filter } 
                placeholder={ appContext.t(`candidate.list.labelFilter${ candidateMode ? 'Candidate' : '' }`) }
                onChange={ onChangeSearch }
                onKeyDown={ onKeyDownSearch }
                onClick={ () => onClickSearch(appContext.filter, appContext.statusFilter, appContext.dateFilter, appContext.langFilter, appContext.levelFilter) }
              />
            </div>
            <label>{ `${total} ${ buildLabelResult() }` }</label>
            { showReload && !modal &&  <div className={ styles.separator } /> }
            {
              showReload && !modal && (
                <div className={ styles.reload } onClick = { () => reload() }>
                  <img src={ reloadIcon } alt='' />
                  <label>{ appContext.t(`candidate.list.labelReload${ candidateMode ? 'Candidate' : '' }`) }</label>
                </div>
              )
            }
          </div>
          {/* <div className={ styles.buttons }>
            <button onClick={() => onClickDetail() }>{ appContext.t('candidate.list.labelBtnAdd') }</button>
          </div> */}
        </div>
        { renderSelectsFilter() }
        { renderTable() }
        { !modal && talents.length === 0 && renderNotFound() }
        { modal }
        <Pagination
          totalElements={ talents.length }
          currentPage={ appContext.currentPage }
          totalPages={ totalPages }
          onClickBackPage={ onClickBackPage }
          onClickForwardPage={ onClickForwardPage }
        />
      </div>
    </div>
  )
}

TalentList.propTypes = {
  candidateMode: PropTypes.bool
};