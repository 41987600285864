import PropTypes from 'prop-types'
import styles from './loading.module.scss'
import { useContext } from 'react'
import { AppContext } from '../../../pages/candidates/context/AppProvider'

export const Loading = ({
    label
}) => {

    const appContext = useContext(AppContext);

    return (
        <div className={ styles.loading_container }>
            <div className={ styles.loading_container_content }>
                <dotlottie-player 
                    src="https://lottie.host/037bf483-369d-4386-b0e8-c2f1769e1e27/MU9K5REm1o.json"
                    background="transparent"
                    speed="1"
                    style={{width: '300px', height: '300px'}} 
                    loop autoplay>
                </dotlottie-player>
                <label>{label ? label : appContext.t('loading2')}</label>
            </div>
        </div>
    )
}

Loading.propTypes = {
    label: PropTypes.string
}