import Axios from 'axios';
import { talentStatus, candidateStatus } from '../core/hardcode';

const jobBoardApi = process.env.REACT_APP_JOB_BOARD_API;
const feedbackApi = process.env.REACT_APP_FEEDBACK_API;
const eStaffingCvApi = process.env.REACT_APP_ESTAFFING_CV_API;
const catalogApiUrl = process.env.REACT_APP_CATALOG_API;

export const getTalents = (candidateMode, filter, page=0, size=10, statusFilter, dateFilter, langFilter, levelFilter, clientFilter) => {
    const filterParam = filter ? `&filterNameOrProfile=${filter}` : '';
    let filterStatus = '';
    if( statusFilter && statusFilter.length > 0 ) {
        statusFilter.forEach( s => filterStatus += `&statuses=${s.id}` )
    } else if( candidateMode ) {
        candidateStatus.forEach( statusId => filterStatus += `&statuses=${statusId}` )
    } else {
        talentStatus.forEach( statusId => filterStatus += `&statuses=${statusId}` )
    }
    const dateFilterParam = dateFilter && dateFilter.range ? `&startUpdatedAt=${dateFilter.range.start}&endUpdatedAt=${dateFilter.range.end}` : '';
    const langParam = langFilter ? `&languages=${langFilter.id}` : '';
    const levelParam = levelFilter ? `&langlevels=${levelFilter.id}` : '';
    let clientParam = '';
    if( clientFilter ) {
        clientFilter.forEach( c => clientParam += `&clientIds=${c.id}` )
    }
    const sorter = candidateMode ? 'updatedAt,DESC' : 'firstName,ASC';
    return Axios.get(`${jobBoardApi}talent/list?page=${page}&size=${size}&sort=${sorter}${filterStatus}${filterParam}${dateFilterParam}${langParam}${levelParam}${clientParam}`);
}

export const getTalent = talentId => {
    return Axios.get(`${jobBoardApi}talent/${talentId}`);
}

export const getFeedbacks = (userId, talentId) => {
    return Axios.get(`${feedbackApi}user/${userId}/talent/${talentId}/feedback/list`)
}

export const saveFeedback = (userId, talentId, feedback) => {
    return Axios.post(`${feedbackApi}user/${userId}/talent/${talentId}/feedback/save`, feedback)
}

export const downloadCv = talentId => {
    return Axios.get(`${eStaffingCvApi}talent/${talentId}/downloadcv`, {
        responseType: 'blob'
    })
}
export const getSkills = talentId => {
    return Axios.get(`${jobBoardApi}talent/${talentId}/skills/`);
}

export const getSkillsCatalog = (talentId, page ,size) => {
    return Axios.get(`${catalogApiUrl}feedback/${talentId}/skills/?page=${page}&size=${size}&sort=name&direction=ASC`)
};

export const setTalentStatus = (talentId, statusId, userId) => {
    return Axios.put(`${jobBoardApi}talent/${talentId}/setStatus?statusId=${statusId}&userId=${userId}`)
}

export const getPostulations = talentId => {
    return Axios.get(`${jobBoardApi}talent/${talentId}/application/`);
}

export const getViewers = talentId => {
    return Axios.get(`${jobBoardApi}talent/${talentId}/viewers`);
}

export const saveViewers = (talentId, userId, viewerIds) => {
    return Axios.post(`${jobBoardApi}talent/${talentId}/viewers/save?userId=${userId}`, viewerIds)
}