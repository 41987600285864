import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../candidates/context/AppProvider';
import { Searcher } from '../../../components/talent-module/searcher/Searcher';
import styles from '../../talents/talentInfo.module.scss';
import reloadIcon from '../../../../assets/icons/reload.svg';
import linkIcon from '../../../../assets/icons/link.svg';
import { Pagination } from '../../../components/talent-module/pagination/Pagination';
import { Loading } from '../../../components/talent-module/loading/Loading';
import { SelectMultiple } from '../../../components/talent-module/searcher/SelectMultiple';
import { objEquals, } from '../../../core/helper';
import { getClientCatalog } from '../../../api/catalogClient';
import { defaultStringEmpty } from '../../../core/talent-module/helper';
import { InfoMessage, InfoMessageType } from '../../talents/components/misc/InfoMessage';
import { getAllUsers } from '../../../api/userClient';

export const UserList = () => {

  const appContext = useContext(AppContext);

  const [catClient, setCatClient] = useState([]);
  const [modal, setModal] = useState();
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [showReload, setShowReload] = useState(false);

  const loadCatClient = () => {
    getClientCatalog().then( response => {
      if( response.status === 200 && response.data && response.data.code === 200 && response.data.result && Array.isArray(response.data.result) ) {
        let clientsTmp = [];
        response.data.result.map( l => clientsTmp.push({ id: l.id, optionName: l.name}))
        setCatClient(clientsTmp);
    }
    }).catch( error => {
      console.log(error);
    })
  }

  const fetchUsers = (filter, clientFilter, currentPage) => {
    setTotal(0);
    setTotalPages(0);
    setUsers([]);
    setModal(<Loading />);
    getAllUsers(filter, null, clientFilter, true, currentPage, 10).then( response => {
      if( response.status === 200 && response.data && response.data.code === 200 && response.data.result && response.data.result.content && Array.isArray(response.data.result.content) ) {
        const usersTmp = [];
        response.data.result.content.map(u => {
          const user = {
            id: u.id,
            name: `${u.firstName}${u.lastName ? ' ' + u.lastName : ''}`,
            clientName: u.clientName,
            areaName: u.area && u.area.name ? u.area.name : '',
            email: u.email,
            boardLink: u.boardLink
          }
          usersTmp.push(user);
        })
        setUsers(usersTmp);
        setTotalPages(response.data.result.totalPages);
        setTotal(response.data.result.totalElements);
        setShowReload(response.data.result.totalElements === 0 || filter !== '' || clientFilter.length > 0 );
        setModal(null);
      } else {
        setModal(null);
      }
    }).catch( error => {
      console.log(error);
      setModal(null);
    })
  }

  useEffect(() => {
    loadCatClient();
    fetchUsers(appContext.filter, appContext.clientFilter, appContext.currentPage);
  }, [appContext.filter, appContext.clientFilter]);

  const onKeyDownSearch = ({key}) => {
    const isEmpty = key === 'Backspace' && appContext.filter.length === 1;
    if ( key === 'Enter' || isEmpty ) {
      setShowReload(!isEmpty);
      onClickSearch(isEmpty ? '' : appContext.filter);
    }
  }

  const onChangeSearch = ({target}) => {
    appContext.setFilter(target.value);
  }
  
  const onClickSearch = (filter) => {
    appContext.setCurrentPage(0);
    fetchUsers(filter, appContext.clientFilter, 0);
  }

  const reload = () => {
    setShowReload(false);
    appContext.cleanFilter();
    fetchUsers('', [], [], 0);
  }

  const onClickBackPage = () => {
    if( appContext.currentPage > 0 ) {
      appContext.setCurrentPage(appContext.currentPage - 1);
      fetchUsers(appContext.filter, appContext.clientFilter, appContext.currentPage - 1);
    }
  }

  const onClickForwardPage = cP => {
    if( appContext.currentPage + cP < totalPages ) {
      appContext.setCurrentPage(appContext.currentPage + 1);
      fetchUsers(appContext.filter, appContext.clientFilter, appContext.currentPage + 1);
    }
  }

  const onClickSelectFilter = (option, value, setValue) => {
    const newValue = value.find( v => objEquals(v,option) ) ? value.filter( v => v.id !== option.id ) : [ ...value, option ];
    setValue(newValue);
  }

  const renderSelectsFilter = () => (
    <div className={ styles.container_list_sel }>
      <SelectMultiple
        name='clientFilter'
        value = { appContext.clientFilter }
        setValue={ appContext.setClientFilter }
        options = { catClient }
        placeholder = { appContext.t('candidate.list.labelClientFilter') }
        onChange = { onClickSelectFilter }
      />
    </div>
  )

  const renderNotFound = () => {
    let title = '';
    let subtitles = [];
    let cssParagraph = '';
    let type = null;
    if( users.length === 0 && appContext.filter === '' ) {
      title = appContext.t('catalogs.user.notFound')
      subtitles.push(appContext.t('candidate.notFound.subtitle'));
      subtitles.push(appContext.t('candidate.notFound.detail'));
      type = InfoMessageType.notFound;
      cssParagraph = styles.pharagrap_multiple;
    } else if (users.length === 0 && appContext.filter !== '') {
      title = appContext.t('notMatch.title');
      subtitles.push(appContext.t('notMatch.subtitle'));
      type = InfoMessageType.notMatch;
      cssParagraph = styles.pharagrap_simple;
    }
    return <InfoMessage title={ title } subtitles={ subtitles } type={ type } cssParagraph={ cssParagraph } shadow />
  }

  const onClickCopyLink = boardLink => {
    if( boardLink ) {
      navigator.clipboard.writeText(boardLink);
      appContext.displayNotification(appContext.t('catalogs.user.linkCopied'));
    }
  }

  const renderItems = () => users.map( (user, index) => (
    <tr key={ index }>
      <td className={ styles.first_child_border }>
        <div className={ styles.td_column }>
          <label className={ styles.name }>{ defaultStringEmpty(user.name) }</label>
        </div>
      </td>
      <td className={ styles.textleft }>{ defaultStringEmpty(user.clientName) }</td>
      <td className={ styles.textleft }>{ defaultStringEmpty(user.areaName) }</td>
      <td className={ styles.textleft }>{ defaultStringEmpty(user.email) }</td>
      <td className={ `${styles.last_child_border} ${styles.textcenter}` } onClick={ () => onClickCopyLink(user.boardLink) }>
        { user.boardLink && <img src={ linkIcon } alt='' /> }
      </td>
    </tr>
  ) );

  const renderTable = () => (
    <table>
      <thead>
        <tr>
          <th className={ `${styles.first_child_border} ${styles.textleft}` } style={{ width: '25%' }}>{appContext.t('candidate.referrel.list.headers.name')}</th>
          <th className={ styles.textleft } style={{ width: '25%' }}>{appContext.t('candidate.list.headers.client')}</th>
          <th className={ styles.textleft } style={{ width: '20%' }}>{appContext.t('candidate.resume.assignment.labelArea')}</th>
          <th className={ styles.textleft } style={{ width: '25%' }}>{appContext.t('candidate.referrel.list.headers.email')}</th>
          <th className={ `${styles.last_child_border} ${styles.textleft}` } style={{ width: '5%' }}>{appContext.t('catalogs.user.link')}</th>
        </tr>
      </thead>
      <tbody>
        { renderItems() }
      </tbody>
    </table>
  )

  const buildLabelResult = () => {
    let entityString = appContext.t('catalogs.user.labelTotal');
    if( total === 1 && entityString.endsWith('s') ) {
      entityString = entityString.substring(0, entityString.length-1);
    }
    let complementString = appContext.filter !== '' || appContext.clientFilter.length > 0 ? appContext.t('candidate.list.labelNoFound') : '';
    if( total === 1 && complementString.endsWith('s') ) {
      complementString = complementString.substring(0, complementString.length-1);
    }
    return `${entityString} ${complementString}`;
  }

  return (
    <div className={ styles.container } >
      <div className={ styles.wrapper }>
        <div className={ styles.container_list_btn }>
          <label className={ styles.title }>{ appContext.t('menu.user.title') }</label>
        </div>
        <div className={ styles.container_list_btn }>
          <div className={ styles.btn_label }>
            <div className={ styles.width_40 }>
              <Searcher 
                name={'filter'} 
                value={ appContext.filter } 
                placeholder={ appContext.t('catalogs.profile.labelFilter') }
                onChange={ onChangeSearch }
                onKeyDown={ onKeyDownSearch }
                onClick={ () => onClickSearch(appContext.filter) }
              />
            </div>
            <label>{ `${total} ${ buildLabelResult() }` }</label>
            { showReload && !modal &&  <div className={ styles.separator } /> }
            {
              showReload && !modal && (
                <div className={ styles.reload } onClick = { () => reload() }>
                  <img src={ reloadIcon } alt='' />
                  <label>{ appContext.t('catalogs.user.labelReload') }</label>
                </div>
              )
            }
          </div>
        </div>
        { renderSelectsFilter() }
        { renderTable() }
        { !modal && users.length === 0 && renderNotFound() }
        { modal }
        <Pagination
          totalElements={ users.length }
          currentPage={ appContext.currentPage }
          totalPages={ totalPages }
          onClickBackPage={ onClickBackPage }
          onClickForwardPage={ onClickForwardPage }
        />
      </div>
    </div>
    
  )
}
