import PropTypes from 'prop-types';
import styles from './pagination.module.scss';
import arrowIcon from '../../../../assets/icons/arrow_pagination.svg';
import { useContext } from 'react';
import { AppContext } from '../../../pages/candidates/context/AppProvider';

export const Pagination = ({
    totalElements,
    currentPage,
    totalPages,
    onClickBackPage,
    onClickForwardPage
}) => {

    const appContext = useContext(AppContext);

    const cP = totalElements > 0 ? 1 : 0;
    const isBackDis = currentPage === 0;
    const isForwDis = totalPages === 0 || currentPage + cP === totalPages;
    const cssBackButton = isBackDis ? styles.button_disabled : styles.button_enabled;
    const cssForwardButton = isForwDis ? styles.button_disabled : styles.button_enabled;
    
    return (
        <div className={ styles.paginator }>
            <div className={ `${ styles.button } ${ cssBackButton }` } onClick={ () => onClickBackPage() }>
                <img className={ !isBackDis ? styles.arrow : undefined } src={ arrowIcon } alt='' />
            </div>
            <div className={ styles.label }>{ `${ appContext.t('paginator.label') } ${ currentPage + cP } ${ appContext.t('paginator.of') } ${ totalPages }` }</div>
            <div className={ `${ styles.button } ${ cssForwardButton }` } onClick={ () => onClickForwardPage(cP) }>
                <img className={ `${ styles.rotate } ${!isForwDis ? styles.arrow : undefined}` } src={ arrowIcon } alt='' />
            </div>
        </div>
    )
}

Pagination.propTypes = {
    totalElements: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onClickBackPage: PropTypes.func.isRequired,
    onClickForwardPage: PropTypes.func.isRequired
}