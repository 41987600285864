import Axios from "axios";

const apiUrl = process.env.REACT_APP_VACANCIES_API;

export const getReferrals = (filter, dateFilter, page=0, size=10, sorter='createdDate', direction='desc') => {
    const filterParam = filter ? `&filter=${filter}` : '';
    const dateFilterParam = dateFilter && dateFilter.range ? `&startDate=${dateFilter.range.start}&endDate=${dateFilter.range.end}` : '';
    return Axios.get(`${apiUrl}referrals?paginated=true&page=${page}&size=${size}&sortBy=${sorter}&sortDir=${direction}${filterParam}${dateFilterParam}`);
}

export const getReferral = (email, vacandyId) => Axios.get(`${apiUrl}referrals/${email}/${vacandyId}`);