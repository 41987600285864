import Axios from "axios";

const apiUrl = process.env.REACT_APP_JOB_BOARD_API;
const talentUrl = process.env.REACT_APP_API_URL;

const mainContext = 'talent';

const headerMultiFormData = {
    headers: {
        'Content-type': 'multipart/form-data'
    }
};

const headerAuth = (token, refreshToken) => ({
    headers: {
        'token': token,
        'refreshtoken': refreshToken
    }
})

export const testConnection = () => Axios.get(`${apiUrl}${mainContext}/test`);

export const getTalent = talentId => Axios.get(`${apiUrl}${mainContext}/${talentId}`);

export const saveTalent = request => Axios.post(`${apiUrl}${mainContext}/new`, request, headerMultiFormData);

export const updateTalent = (request, sectionId) => Axios.put(`${apiUrl}${mainContext}/edit?sectionId=${sectionId}`, request, headerMultiFormData);

export const getDataSession = (token, refreshToken) => Axios.get(`${talentUrl}session/getDataSession`, headerAuth(token, refreshToken));