import { useContext, useState } from "react";
import styles from '../../../../components/modal/modal_base.module.scss';
import closeIcon from '../../../../../assets/icons/close.svg';
import { Checkbox } from '../../../../components/inputs/Checkbox';
import { InputText } from '../../../../components/inputs/InputText';
import { MONTHS, selectEmtpy, yearsHardCode, yearsOrderDesc } from '../../../../core/hardcode';
import { CandidateContext } from "../../context";
import { validateIsSelected, validateNotNull } from "../../../../core/validators";
import { serializeDateString } from "../../../../core/helper";
import { SelectRange } from "../../../../components/inputs/SelectRange";
import { AppContext } from "../../context/AppProvider";
import Select, { SelectSortType } from "../../../../components/inputs/Select";
import { academicSorted } from "./academic_info";

export const AcademicInfoModal = ({
    catStudyLevel
}) => {
    const appContext = useContext(AppContext);

    const { 
        academic, 
        setAcademic,
        academics, 
        setAcademics 
    } = useContext( CandidateContext );

    const [catMonths, setCatMonths] = useState(MONTHS);
    const [errors, setErrors] = useState(false);

    const validationFields = {
        name: [validateNotNull],
        school: [validateNotNull],
        fIniMes: [validateIsSelected],
        fIniAnio: [validateIsSelected],
        fTerMes: [validateIsSelected],
        fTerAnio: [validateIsSelected],
        type: [validateIsSelected]
    }
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    const validateRequest = () => {
        const errorObject = {};
        var helper;
        for(const field in validationFields) {
            for (const validator in validationFields[field]) {
                helper = validationFields[field][validator](academic[field])
                if (helper) {
                    errorObject[field] = helper
                }
            }
        }
        if( academic.stillStudy ) {
            delete errorObject.fTerMes;
            delete errorObject.fTerAnio;
        }
        // validando que fecha de inicio sea mayor que la fecha de término
        if(!academic.stillStudy && Date.parse(academic.fIniAnio.description + "-" + academic.fIniMes.id + "-01") > Date.parse(academic.fTerAnio.description + "-" + academic.fTerMes.id + "-01")) {
            errorObject.fIniAnio = [' ']
            errorObject.fIniMes = [' ']
            errorObject.fTerAnio = [' ']
            errorObject.fTerMes = [appContext.t('validator.daterange')]
        }
        return errorObject
    }

    const handleOnClickAdd = () => {
        const validationErrors = validateRequest();
        if (Object.keys(validationErrors).length === 0) {
            const startDate = (academic.fIniMes && academic.fIniAnio && academic.fIniMes.id !== 0 && academic.fIniAnio.id !== 0) 
                ? serializeDateString(academic.fIniMes.id, academic.fIniAnio.description) : null;
            const endDate = (academic.fTerMes && academic.fTerAnio && academic.fTerMes.id !== 0 && academic.fTerAnio.id !== 0) 
                ? serializeDateString(academic.fTerMes.id, academic.fTerAnio.description) : null;
            const academicTmp = {
                ...academic,
                'endDate': endDate,
                'startDate': startDate,
                'startDateParsed': `${academic.fIniMes.description} ${academic.fIniAnio.description}`,
                'endDateParsed': (!academic.stillStudy ? `${academic.fTerMes.description} ${academic.fTerAnio.description}` : appContext.t('noData.inProg')),

            };
            setAcademic(academicTmp);
            const academicExist = academics[academic.index];
            let academicsTmp = [...academics];
            if( academicExist ) {
                academicsTmp[academic.index] = academicTmp;
            } else {
                academicsTmp.unshift(academicTmp);
            }
            setAcademics(academicSorted(academicsTmp));
            appContext.setModalFlag(false)
        } else {
            setErrors(validationErrors)
        }
    }

    const updateWorkAcademic = (property, value) => {
        setAcademic({ ...academic, [property]: value});
        if(property === "fIniAnio") {
            if(value.description === currentYear ) {
                if(academic.fIniMes.id === 0) {
                    setCatMonths(MONTHS.slice(0, currentMonth + 1));
                }
            } else {
                setCatMonths(MONTHS);
            }
        } else if(property === "fTerAnio") {
            if(value.description === currentYear ) {
                if(academic.fIniMes.id === 0) {
                    setCatMonths(MONTHS.slice(0, currentMonth + 1));
                } else {
                    if(academic.fIniMes.id > currentMonth + 1) {
                        handleCheckstudyYet("stillStudy", true);
                    }
                }
            } else {
                setCatMonths(MONTHS);
            }
        }
    }

    const handleCheckstudyYet = (property, value) => {
        if( value ) {
            setAcademic({
                ...academic,
                [property]: value,
                fTerMes: selectEmtpy,
                fTerAnio: selectEmtpy,
            });
        } else {
            setAcademic({ ...academic, [property]: value});
        }
    }

    const updateStudyLevel = (property, value) => {
        setAcademic({ ...academic, [property]: value, [`${property}Id`]: value.id, [`${property}Name`]: value.description});
    }
    
    return (
        <div className={ styles.modal_container }>
            <div className={ styles.close }>
                <img src={ closeIcon } alt="" onClick={() => appContext.setModalFlag(false)} />
            </div>
            <p className={ styles.title }>{ `${ academic.courseId && academic.courseId > 0 ? appContext.t('button.edit') : appContext.t('button.add')} ${appContext.t('candidate.resume.academic.title')}` }</p>
            <div className={ styles.form_container }>
                <div className={ styles.form_wrapper }>
                    <div className={ styles.form_columns }>
                        <InputText
                            cssContainer={ styles.form_column }
                            name='name'
                            label={ `${appContext.t('candidate.resume.academic.name')}*` }
                            value={ academic.name } 
                            maxLength={ 150 }
                            onChange={ updateWorkAcademic } 
                            error={ Object.keys(errors).includes("name") ? errors.name[0] : '' }
                        />
                        <InputText
                            cssContainer={ styles.form_column }
                            name='school'
                            label={ `${appContext.t('candidate.resume.academic.school')}*` }
                            value={ academic.school }
                            maxLength={ 200 }
                            onChange={ updateWorkAcademic } 
                            error={ Object.keys(errors).includes("school") ? errors.school[0] : '' }
                        />
                    </div>
                    <div className={ styles.form_columns }>
                        <div className={ styles.form_column }>
                            <div className={ styles.custom_select }>
                                <label>{ appContext.t('candidate.resume.academic.study') }*</label>
                                <Select
                                    name="type"
                                    placeholder={ appContext.t('candidate.resume.academic.chooseStudy') }
                                    value={ academic.type }
                                    onChange={ updateStudyLevel }
                                    options={ catStudyLevel }
                                    error= { Object.keys(errors).includes("type") ? errors.type[0] : '' }
                                    sort={ SelectSortType.ALPHA }
                                />
                                {
                                    Object.keys(errors).includes("type") && (
                                        <p className={ styles.error_message }>
                                            { errors.type ? `${errors.type[0]}*` : '' }
                                        </p>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <p className={ styles.form_subtitle }>{ appContext.t('candidate.resume.courses.period') }</p>
                    <div className={ styles.form_columns }>
                        <div className={ styles.form_column }>
                            <label>{ appContext.t('candidate.resume.labelStartDate') }*</label>
                            <SelectRange 
                                prop1={{
                                    name: 'fIniMes',
                                    onChange: updateWorkAcademic,
                                    options: catMonths,
                                    placeholder: appContext.t('candidate.resume.labelMonth'),
                                    value: academic.fIniMes,
                                    error: (Object.keys(errors).includes("fIniMes")) ? errors.fIniMes[0] : ''
                                }}
                                prop2={{
                                    name: 'fIniAnio',
                                    onChange: updateWorkAcademic,
                                    options: yearsHardCode(yearsOrderDesc),
                                    placeholder: appContext.t('candidate.resume.labelYear'),
                                    value: academic.fIniAnio,
                                    error: (Object.keys(errors).includes("fIniAnio") ) ? errors.fIniAnio[0]  : ''
                                }}
                            />
                        </div>
                        <div className={ styles.form_column }>
                            <label>{ appContext.t('candidate.resume.labelEndDate') }*</label>
                            <SelectRange 
                                prop1={{
                                    name: 'fTerMes',
                                    onChange: updateWorkAcademic,
                                    options: catMonths,
                                    placeholder: appContext.t('candidate.resume.labelMonth'),
                                    value: academic.fTerMes,
                                    error: (!academic.stillStudy && Object.keys(errors).includes("fTerMes")) ? errors.fTerMes[0] : ''
                                }}
                                prop2={{
                                    name: 'fTerAnio',
                                    onChange: updateWorkAcademic,
                                    options: yearsHardCode(yearsOrderDesc),
                                    placeholder: appContext.t('candidate.resume.labelYear'),
                                    value: academic.fTerAnio,
                                    error: (!academic.stillStudy && Object.keys(errors).includes("fTerAnio") ) ? errors.fTerAnio[0]  : ''
                                }}
                                disabled={ academic.stillStudy }
                            />
                            <Checkbox
                                cssLabel = { styles.subtitle } 
                                name="stillStudy"
                                label = { appContext.t('candidate.resume.academic.stillStudy') }
                                value={ academic.stillStudy }
                                onClick={ handleCheckstudyYet } />
                        </div>
                    </div>
                </div>
                <div className={ styles.buttons }>
                    <button className={ styles.cancel_button + " " + styles.mobile } onClick={() => appContext.setModalFlag(false)}>{ appContext.t('button.cancel') }</button>
                    <button onClick={() => handleOnClickAdd()}>{ academic.courseId && academic.courseId > 0 ? appContext.t('button.save') : appContext.t('button.add') }</button>
                </div>
            </div>
        </div>
    )
}
