import Axios from 'axios';

const apiUrl = process.env.REACT_APP_JOB_BOARD_API;

const mainContext = 'talent';
const module = 'education';

export const newEducation = (talentId, workExperience, userId) => Axios.post(`${apiUrl}${mainContext}/${talentId}/${module}/new?userId=${userId}`, workExperience);

export const editEducation = (talentId, workExperience, userId) => Axios.put(`${apiUrl}${mainContext}/${talentId}/${module}/edit?userId=${userId}`, workExperience);

export const getEducations = talentId => Axios.get(`${apiUrl}${mainContext}/${talentId}/${module}/`);

export const deleteEducation = (talentId, id, userId) => Axios.delete(`${apiUrl}${mainContext}/${talentId}/${module}/${id}/delete?userId=${userId}`);