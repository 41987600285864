import styles from './input.module.scss';
import { validateLowerCharacter, validateMinLength, validateNumber, validateUpperCase } from '../../core/validators';

import {ReactComponent as ValidationIcon} from '../../../assets/icons/check_gray.svg';
import { useContext } from 'react';
import { AppContext } from '../../pages/candidates/context/AppProvider';

export const PasswordSuggestion = ({
    value,
    requestFlag,
    bottom,
    arrowBottom
}) => {

    const appContext = useContext(AppContext);

    return (
        <div className={ styles.password_validator } style={{ bottom: `${bottom}%` }}>
            <div className={ styles.validator_row }>
                <p className={ styles.validator_title }>{appContext.t('recovery.pswdMust')}</p>
            </div>
            <div className={ styles.validator_row  +  " " + (!validateMinLength(value) ? styles.validated : (((validateMinLength(value) && requestFlag ) ?  styles.error : undefined )) )  }>
                <ValidationIcon />
                <p>{appContext.t('recovery.suggestions.minlen')}</p>
            </div> 
            <div className={ styles.validator_row +  " " + (!validateLowerCharacter(value) ? styles.validated : (((validateLowerCharacter(value) && requestFlag ) ?  styles.error : undefined )) ) }>
                <ValidationIcon />
                <p>{appContext.t('recovery.suggestions.lowchar')}</p>
            </div>
            <div className={ styles.validator_row +  " " + (!validateUpperCase(value) ? styles.validated : (((validateUpperCase(value) && requestFlag ) ?  styles.error : undefined ))) }>
                <ValidationIcon />
                <p>{appContext.t('recovery.suggestions.upperchar')}</p>
            </div>
            <div className={ styles.validator_row +  " " + (!validateNumber(value) ? styles.validated : (((validateNumber(value) && requestFlag ) ?  styles.error : undefined ))) }>
                <ValidationIcon />
                <p>{appContext.t('recovery.suggestions.number')}</p>
            </div>
            <div className={ styles.box_notch } style={{ bottom: `${arrowBottom}%` }}></div>
        </div>
    )
}
