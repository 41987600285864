import { useContext } from 'react';
import { AppContext } from '../../context/AppProvider';
import styles from '../../candidateInfo.module.scss';
import editIcon from '../../../../../assets/icons/orange_pencil.svg';

export const LanguagesResume = (
    {
        personalInfo,
        updateView
    }
) => {

    const appContext = useContext(AppContext);

    return (
        <div className={ styles.section }>
            <div className={ styles.section_header}>
                <div className={ styles.section_title }>
                    <div className={ styles.decorator }></div>
                    { appContext.t('candidate.resume.lang.title') }
                </div>
                <div className={ styles.button_container }>
                    <div className={ styles.vertical_separator }></div>
                    <button className={ styles.add_info } onClick={() => updateView(4, appContext.t('candidate.resume.lang.title'))}>
                        { appContext.t('candidate.resume.edit') }
                        <img src={ editIcon } alt="" />
                    </button>
                </div>
            </div>
            <div className={ styles.cards_wrapper }>
                {
                    personalInfo.languages?.map((languageInfo, index) => {
                        return(
                            <div key={ index } className={ styles.info_card }>
                                <div className={ styles.language_info }>
                                    <label >{`${languageInfo.languageName} ${languageInfo.langLevelName} - ${ languageInfo.langLevelDescription ? languageInfo.langLevelDescription.split(":")[0] : appContext.t('noData.unspec') } `} </label>
                                    <p>{ languageInfo.langLevelDescription ? languageInfo.langLevelDescription.split(":")[1] : '' }</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}