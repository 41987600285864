import { useContext, useEffect } from 'react';
import { AppContext } from '../../pages/candidates/context/AppProvider';
import styles from './input.module.scss';
import seniorityJrIcon from '../../../assets/icons/seniority_jr.svg';
import seniorityMdIcon from '../../../assets/icons/seniority_md.svg';
import senioritySrIcon from '../../../assets/icons/seniority_sr.svg';

export const Seniority = ({
    name,
    label,
    value,
    catSeniority,
    onChange,
    error,
    focus = false
}) => {

    const appContext = useContext(AppContext);

    useEffect(() => {
        if( focus ) {
            appContext.contentScrollUp();
        }
    }, [focus]);

    const renderIcon = id => {
        let imgIcon = '';
        switch (id) {
            case 1000300001:
                imgIcon = seniorityJrIcon
                break;
            case 1000300002:
                imgIcon = seniorityMdIcon
                break;
            case 1000300003:
                imgIcon = senioritySrIcon
                break;
            default:
                break;
        }
        return <img src={ imgIcon } alt="" />
    }

    const renderSeniority = () => catSeniority.map( (s, index) => (
        <div key={ index } className={ `${styles.seniority_content_item} ${value === s.id ? styles.seniority_content_item_selected : undefined}` } onClick={ () => onChange(name, s.id) }>
            { renderIcon(s.id) }
            <div className={ styles.seniority_content_item_label }>
                <label>{ s.optionName }</label>
                { s.description }
            </div>
        </div>
    ))

    return (
        <div className={ styles.error_container }>
            <div className={ styles.seniority }>
                <label htmlFor="">{ label }</label>
                <div className={ styles.seniority_content }>{ renderSeniority() }</div>
                { error && (<p className={ styles.error_message_font }>{ error }</p>) }
            </div>
        </div>
    )
}
