import PropTypes from 'prop-types';
import styles from './input.module.scss';
import checkboxIcon from '../../../assets/icons/checkbox_uncheck.svg'
import checkboxCheckedIcon from '../../../assets/icons/checkbox_checked.svg'
import { borderErrorCheck } from '../../core/helper';

export const Checkbox = ({
    cssLabel,
    name,
	value,
	label,
	onClick,
    error
}) => {

    const handleOnCheck = checkValue => {
        onClick(name, checkValue);
    }

    return (
        <div className={ styles.error_container }>
            <div className={ styles.check_container } style={ borderErrorCheck(error) } onClick={ () => handleOnCheck(!value) }>
                <img src={ value ? checkboxCheckedIcon : checkboxIcon } alt="" />
                { cssLabel ? <label className={ cssLabel }>{ label }</label> : label }
            </div>
            { error && (<p className={ styles.error_message_font }>{ error }</p>) }
        </div>
    )
}

Checkbox.propTypes = {
	value: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};