import PropTypes from 'prop-types';
import styles from './input.module.scss';
import { borderError, stringToFormatDate } from '../../core/helper';

export const InputText = ({
    cssContainer,
    name,
	label,
    subLabel,
    maxLength = 100,
    placeholder = '',
    value,
    modeDate = false,
	onChange,
    error,
    marginError
}) => {

    const handleOnChange = ({ target }) => {
        onChange(name, modeDate ? stringToFormatDate(target.value) : target.value);
    }

    return (
        <div className={ cssContainer } >
            { label && <label>{ label }</label> }
            { subLabel }
            <div className={ styles.error_container }>
                <input 
                    style={ borderError(error) }
                    autoComplete='off'
                    type="text"
                    name={ name } 
                    placeholder={ placeholder }
                    maxLength={ maxLength }
                    value={ value } onChange={ handleOnChange }
                />
                { error && (<p className={ styles.error_message_font }>{ error }</p>) }
                { marginError && '\u00A0' }
            </div>
        </div>
    )
}

InputText.propTypes = {
    name: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string
};