import { useContext } from 'react';
import styles from '../../candidateInfo.module.scss';
import { AppContext } from '../../context/AppProvider';
import editIcon from '../../../../../assets/icons/orange_pencil.svg';
import { defaultStringEmpty } from '../../../../core/talent-module/helper';

export const VisibilityResume = ({
    personalInfo,
    updateView
}) => {

    const appContext = useContext(AppContext);

    return (
        <div className={ styles.section }>
            <div className={ styles.section_header}>
                <div className={ styles.section_title }>
                    <div className={ styles.decorator }></div>
                    { appContext.t('candidate.resume.visibility.title') }
                </div>
                <div className={ styles.button_container }>
                    <div className={ styles.vertical_separator }></div>
                    <button className={ styles.add_info } onClick={() => updateView(20, appContext.t('candidate.resume.visibility.title'))}>
                        { appContext.t('candidate.resume.edit') }
                        <img src={ editIcon } alt="" />
                    </button>
                </div>
            </div>
            <div className={ `${styles.section_header} ${styles.section_subtitle}` }>
                { appContext.t('candidate.resume.visibility.labelInst') }
            </div>
            {
                personalInfo.visibility && personalInfo.visibility.length > 0 &&
                <div className={ `${styles.section_header} ${styles.section_subtitle}` }>
                    <label>{ `${appContext.t('candidate.resume.visibility.labelInfo')} ${ appContext.talent.firstName}:` }</label>
                </div>
            }
            {
                personalInfo.visibility && personalInfo.visibility.length > 0 && personalInfo.visibility.map((v, index) => (
                    <div key={ index }>{ defaultStringEmpty(`${v.firstName ? v.firstName : ''}${v.lastName ? ' ' + v.lastName : ''}`) }</div>
                ))
            }
        </div>
    )
}
