import { useContext } from 'react';
import { AppContext } from '../../context/AppProvider';
import styles from '../../candidateInfo.module.scss';
import addPictureIcon from '../../../../../assets/icons/add_photo.svg';
import envelopeIcon from '../../../../../assets/icons/envelope.svg';
import locationIcon from '../../../../../assets/icons/location.svg';
import currencyIcon from '../../../../../assets/icons/currency_symbol.svg';
import phoneIcon from '../../../../../assets/icons/phone.svg';
import { defaultStringEmpty } from '../../../../core/talent-module/helper';

export const PersonalInfoResumeDetail = (
    {
        personalInfo,
        updateView,
        candidateMode
    }
) => {

    const appContext = useContext(AppContext);

    return (
        <div className={ styles.personal_info_wrapper }>
            <div className={styles.profile_info }>
                <div className={ styles.picture_column}>
                    {
                        personalInfo.imgProfile ? (
                            <div className={ styles.candidate_profile_picture }>
                                <img src={ `${personalInfo.imgProfile}?${new Date().getTime()}` } alt="" />
                            </div>
                        ) : (
                            <button onClick={() => updateView(0, appContext.t('candidate.resume.info.title')) }>
                                <img src={ addPictureIcon } alt="" />
                            </button>
                        )
                    }
                </div>
                <div className={ styles.candidate_info_columns }>
                    <p className={ styles.candidate_name }>{ `${personalInfo.firstName} ${personalInfo.lastName}` }</p>
                    <div className={ styles.candidate_contact_info }>
                        <p className={ styles.candidate_position}>{ `${personalInfo.position}${personalInfo.seniority ? ' ' + personalInfo.seniority : ''}` }</p>
                        <div className={ styles.personal_info_separator }></div>
                        <p className={ styles.candidate_position}>{ personalInfo.statusName ? personalInfo.statusName : appContext.talent.statusName }</p>
                    </div>
                    <div className={ styles.candidate_contact_info }>
                        <div className={ styles.contact_field }>
                            <img src={ envelopeIcon } alt="" />
                            <p>{ personalInfo.email ? personalInfo.email : appContext.talent.email }</p>
                        </div>
                        <div className={ styles.personal_info_separator }></div>
                        <div className={ styles.contact_field }>
                            <img src={ phoneIcon } alt="" />
                            <p>{ personalInfo.phone || appContext.t('noData.notset') }</p>
                        </div>
                        <div className={ styles.personal_info_separator }></div>
                        <div className={ styles.contact_field }>
                            <img src={ locationIcon } alt="" />
                            {
                                (personalInfo.country && personalInfo.state) ? (
                                    <p>{ personalInfo.country + ", " + personalInfo.state  }</p>
                                ) : (
                                    <p>{ appContext.t('noData.notset') }</p>
                                )
                            }
                        </div>
                        <div className={ styles.personal_info_separator }></div>
                        <div className={ styles.contact_field }>
                            <img src={ currencyIcon } alt="" />
                            <p>{ defaultStringEmpty(personalInfo.currency) }</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={ styles.candidate_contact_info_mobile }>
                <div className={ styles.contact_field }>
                    <img src={ envelopeIcon } alt="" />
                    <p>{ personalInfo.email ? personalInfo.email : appContext.talent.email }</p>
                </div>
                <div className={ styles.personal_info_separator }></div>
                <div className={ styles.contact_field }>
                    <img src={ phoneIcon } alt="" />
                    <p>{ personalInfo.phone || appContext.t('noData.notset') }</p>
                </div>
                <div className={ styles.personal_info_separator }></div>
                <div className={ styles.contact_field }>
                    <img src={ locationIcon } alt="" />
                    {
                        (personalInfo.country && personalInfo.state) ? (
                            <p>{ personalInfo.country + ", " + personalInfo.state  }</p>
                        ) : (
                            <p>{ appContext.t('noData.notset') }</p>
                        )
                    }
                </div>
            </div>
            <div className={ styles.about_candidate }>
                <label htmlFor="">{ appContext.t('candidate.resume.info.aboutCandidate') }</label>
                <p>{ personalInfo.description }</p>
            </div>
            { !candidateMode && 
                <div className={ `${styles.about_candidate} ${styles.section_subtitle}` }>
                    <label htmlFor="">{ appContext.t('candidate.resume.info.competenceProfile') }</label>
                    <p>{ defaultStringEmpty(personalInfo.profile) }</p>
                </div>
            }
        </div>
    )
}