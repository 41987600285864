import {
    useContext,
    useEffect
} from 'react';
import { CandidateContext } from '../../context';
import styles from '../../candidateInfo.module.scss';
import addIcon from '../../../../../assets/icons/add_circle.svg';
import trashIcon from '../../../../../assets/icons/trash.svg';
import editIcon from '../../../../../assets/icons/blue_pencil.svg';
//import { getSkills } from '../../../../api/skillClient';
import { objEquals, setSkillSelecValue } from '../../../../core/helper';
import { AppContext } from '../../context/AppProvider';
import { hardSkillsCatalogId, softSkillsCatalogId, softwareCatalogId } from '../../../../core/talent-module/hardcode';
import { InputSearcher } from './InputSearcher';
import { InputPercentage } from './InputPercentage';
import { candidateFormsDesfase, newSkill, selectEmtpy } from '../../../../core/hardcode';

export const validateSkillRequest = (categoryId, skillsCache, appContext) => {
    const skillsCacheFiltred = skillsCache.filter(skill => skill.categoryId === categoryId);
    const errorDictHelper = {}
    let firstFocus = true
    for (const skillObjectIndex in skillsCacheFiltred) {
        const skill = skillsCacheFiltred[skillObjectIndex];
        if (skill.id && skill.skillLevelPercentage) {
            continue
        }
        const id = `${categoryId}_${skillObjectIndex}`;
        const nameEmpty = !skill.id;
        const percEmpty = skill.skillLevelPercentage === 0 || !skill.skillLevelPercentage;
        errorDictHelper[id] = [
            nameEmpty ? appContext.t('validator.required') : "",
            percEmpty ? appContext.t('validator.required') : ""
        ]
        if( firstFocus ) {
            if( nameEmpty ) {
                document.getElementById(id).focus();
            } else if ( percEmpty ) {
                document.getElementById(`per_${id}`).focus();
            }
            firstFocus = false;
        }
    }
    return errorDictHelper
}
const sorterAlphAsc = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if( nameA < nameB ) {
        return -1;
    }
    if( nameA > nameB ) {
        return 1;
    }
    return 0;
}

export const prepareSkills = (skills, catSkills) => {
    let skillsPrepared = [];
    skills.map( (skill, index) => {
        const skillWithTagValue = setSkillSelecValue(skill, index, catSkills);
        skillsPrepared.push(skillWithTagValue);
    })
    skillsPrepared = skillsPrepared.sort( (a, b) => sorterAlphAsc(a,b) );
    return skillsPrepared;
}

export const checkSkillWithCompetition = (skill, competition) => competition.id ? skill.id === competition.tagId : !skill.id;

export const getOptionsSelecteds = skills => {
    const optionsSelected = [];
    skills.forEach( s => optionsSelected.push(s.id) );
    return optionsSelected;
}

const Skills = ({
    resumeViewFlag,
    renderBackButton,
    setStepIndex,
    catSkills,
    loadCatSkills
}) => {

    const appContext = useContext(AppContext)

    const {
        skill,
        cleanSkill,
        skillErrors,
        setSkillErrors,
        skillsCache,
        setSkillsCache,
        competition,
        setCompetition,
        setCompetitionErrors
    } = useContext( CandidateContext );

    const loadSkills = () => {
        // getSkills(appContext.talent.id).then((response) => {
        //     if( response.data && response.data.code === 201 && response.data.result ) {
                // let skillsTmp = [];
                let skillsTmp = [ ...skillsCache ];
                // response.data.result.map( (skill, index) => {
                //     const skillWithTagValue = setSkillSelecValue(skill, index, catSkills);
                //     skillsTmp.push(skillWithTagValue);
                // })
                // skillsTmp = skillsTmp.sort( (a, b) => sorterAlphAsc(a,b) );
                if( competition && competition.categoryId !== softwareCatalogId ) {
                    const index = skillsCache.findIndex(s => checkSkillWithCompetition(s, competition));
                    if( index >= 0 ) {
                        loadCatSkills();
                        skillsTmp[index].id = competition.tagId;
                        skillsTmp[index].tagId = competition.tagId;
                        skillsTmp[index].name = competition.tag.description;
                        skillsTmp[index].tag = competition.tag;
                        skillsTmp[index].categoryId = competition.categoryId;
                    }
                    setCompetition(null);
                }
                // setSkills(skillsTmp);
                setSkillsCache(skillsTmp);
        //     }
        // }).catch(error => {
        //     console.log(error)
        // })
    }

    const onClickAdd = categoryId => {
        // se valida
        const validationHelper = validateSkillRequest(categoryId, skillsCache, appContext);
        if (Object.keys(validationHelper).length === 0) {
            const skillNew = { ...skill, 'categoryId': categoryId };
            cleanSkill(categoryId);
            setSkillsCache([ ...skillsCache, skillNew]);
        }
        setSkillErrors(validationHelper);
    }

    const onSkillEditPage = (value, categoryId) => {
        const competition = {
            ...newSkill,
            id: value.id,
            name: value.description,
            category: { ...selectEmtpy, id: categoryId },
            'categoryId': categoryId
        };
        const title1 = appContext.t(`candidate.resume.skills.addForm.title${ competition.id ? 'Edit' : 'Create' }`);
        const title2 = competition.categoryId === hardSkillsCatalogId ? appContext.t('candidate.resume.skills.addForm.titleHard') : (
            competition.categoryId === softSkillsCatalogId ? appContext.t('candidate.resume.skills.addForm.titleSoft') :
            appContext.t('candidate.resume.skills.addForm.titleSkill')
        )
        const menuNavigation = [...appContext.menuNavigation, { label: `${title1} ${title2}` }];
        setCompetition(competition);
        setCompetitionErrors({});
        appContext.setMenuNavigation(menuNavigation);
        setStepIndex(8 + candidateFormsDesfase);
    }

    const handleOnClickRemove = skill => {
        let skillsCacheTmp = [ ...skillsCache ];
        skillsCacheTmp = skillsCache.filter( s => !objEquals(s.tag, skill.tag));
        setSkillsCache(skillsCacheTmp);
    }

    const updateSkill = (tagId, categoryId, value, isSelected) => {
        let skillsCacheTmp = [ ...skillsCache ];
        const index = skillsCache.findIndex( s => s.tagId === tagId && s.categoryId === categoryId );
        const skill = {
            ...skillsCacheTmp[index],
            tag: value,
            tagId: isSelected ? value.id : tagId,
            id: value.id,
            name: value.description
        }
        skillsCacheTmp[index] = skill;
        setSkillsCache( skillsCacheTmp );
    }

    const updatePercentage = (value, index, categoryId) => {
        let skillsCacheTmp = [ ...skillsCache ];
        skillsCacheTmp.filter( s => s.categoryId === categoryId )[index].skillLevelPercentage = value;
        setSkillsCache(skillsCacheTmp);
    }

    useEffect(() => {
        setSkillErrors({})
        loadSkills();
    }, [])
    

    const renderDeleter = (skill, categoryId, index) => (
        <div className={ styles.action_container }>
            { skill.id ? <img src={ editIcon } alt="" onClick={ () => onSkillEditPage(skill.tag, categoryId) } /> : undefined }
            <img className={ styles.trash } src={ trashIcon } alt="" onClick={ () => handleOnClickRemove(skill, index) } />
        </div>
    )

    const renderSkills = (categoryId, skills) => skills.length > 0 && skills.map((skill, index) => (
        skill.action !== 2 && (
            <div key={ `${categoryId}_${index}` }>
                <div className={ `${styles.form_row} ${styles.padded_short}` }>
                    <div className={ styles.flex_14gap }>
                        <InputSearcher
                            id = {`${categoryId}_${index}`}
                            tagId = { skill.tagId }
                            value = { skill.tag }
                            placeholder = { appContext.t('candidate.resume.skills.labelSearch') }
                            onChange = { updateSkill }
                            options = { catSkills.filter( skill => skill.categoryId === categoryId ) }
                            optionsSelecteds = { getOptionsSelecteds(skills) }
                            error = { Object.keys(skillErrors).includes(`${categoryId}_${index}`) && skillErrors[`${categoryId}_${index}`][0] ? skillErrors[`${categoryId}_${index}`][0] : undefined }
                            index={ index }
                            categoryId = { categoryId }
                            onSkillEditPage = { onSkillEditPage }
                            width={ 40 }
                        />
                        <div className={ styles.flex_14gap }>
                            <InputPercentage
                                id = {`per_${categoryId}_${index}`}
                                value = { skill.skillLevelPercentage }
                                onChange = { updatePercentage }
                                error = { Object.keys(skillErrors).includes(`${categoryId}_${index}`) && skillErrors[`${categoryId}_${index}`][1] ? skillErrors[`${categoryId}_${index}`][1] : undefined }
                                index={ index }
                                categoryId = { categoryId }
                            />
                            { renderDeleter(skill, categoryId, index) }
                        </div>
                    </div>
                </div>
                <div className={ styles.form_row }>
                    <div className={ styles.flex_14gap }>
                        {Object.keys(skillErrors).includes(`${categoryId}_${index}`) && skillErrors[`${categoryId}_${index}`][0] ? (
                            <p className={ styles.error_message } style={{ width: '40%' }}>{ skillErrors[`${categoryId}_${index}`][0] }</p>
                        ) : Object.keys(skillErrors).includes(`${categoryId}_${index}`) && skillErrors[`${categoryId}_${index}`][1] && (
                            <p style={{ width: '40%' }}></p>
                        )}
                        {Object.keys(skillErrors).includes(`${categoryId}_${index}`) && skillErrors[`${categoryId}_${index}`][1] && (
                            <p className={ styles.error_message }>{ skillErrors[`${categoryId}_${index}`][1] }</p>
                        )}
                    </div>
                </div>
            </div>
        )
    ))

    const renderComponentSkill = (categoryId, title, descriptions, buttonLabel) => {
        const skills = skillsCache.filter( skill => skill.categoryId === categoryId );
        return (
            <div className={ styles.form_wrapper }>
                <p className={ styles.form_section_title_2 }>{ title }</p>
                <div className={ `${styles.form_row} ${styles.no_gap} ${ title ? styles.top_padded : ''}` }>
                    { descriptions && descriptions.map((d, index) => (<p key={index} className={ styles.subtitle }>{ d }</p>)) }
                </div>
                { skills.length > 0 &&
                    <div className={ `${styles.form_row} ${styles.no_gap} ${styles.top_padded}` }>
                        <div className={ styles.flex_14gap }>
                            <label style={{ width: '40%' }}>{ appContext.t('candidate.resume.skills.title').replace('s', '*') }</label>
                            <label>{ `${appContext.t('candidate.resume.skills.domain')} (%)*` }</label>
                        </div>
                    </div>
                }
                { renderSkills(categoryId, skills) }
                <div className={ `${styles.form_row} ${styles.top_padded}` }>
                    <button className={ styles.add_info } onClick={ () => onClickAdd(categoryId) }>
                        { buttonLabel }
                        <img src={ addIcon } alt="" />
                    </button>
                </div>
            </div>
        )
    }

    const renderTitle = () => (<p className={ styles.title }>{ appContext.t('candidate.resume.skills.title') }</p>);

    return (
        <div className={ styles.form_container }>
            { appContext.isDesktopOrLaptop ? renderTitle() : ( resumeViewFlag ? renderTitle() : renderBackButton(appContext.t('candidate.resume.skills.title'))) }
            { renderComponentSkill(hardSkillsCatalogId, appContext.t('candidate.resume.skills.hard2'), [appContext.t('candidate.resume.skills.hardRequired')], appContext.t('candidate.resume.skills.labelAddHard')) }
            { renderComponentSkill(softSkillsCatalogId, appContext.t('candidate.resume.skills.soft2'), [appContext.t('candidate.resume.skills.softRequired')], appContext.t('candidate.resume.skills.labelAddSoft')) }
        </div>
    )
}

export default Skills;