export const currentYear = new Date().getFullYear()
export const selectEmtpy = { id: 0, description: '' };

export const MONTHS = [
	{ id: 1, optionName: "Enero"},
	{ id: 2, optionName: "Febrero"},
	{ id: 3, optionName: "Marzo"},
	{ id: 4, optionName: "Abril"},
	{ id: 5, optionName: "Mayo"},
	{ id: 6, optionName: "Junio"},
	{ id: 7, optionName: "Julio"},
	{ id: 8, optionName: "Agosto"},
	{ id: 9, optionName: "Septiembre"},
	{ id: 10, optionName: "Octubre"},
	{ id: 11, optionName: "Noviembre"},
	{ id: 12, optionName: "Diciembre"},
];

const minYear = 1940;
const maxYear = 2050;

export const yearsOrderAsc = 'asc';
export const yearsOrderDesc = 'desc';

export const yearsHardCode = order => {
	const years = [];
	let id = 1;

	if ( order ) {
		const isOrderDesc = order === yearsOrderDesc;
		const yearTo = isOrderDesc ? minYear : maxYear;

		if( isOrderDesc ) {
			for (let index = currentYear; index >= yearTo; index--) {
				const element = {
					id: id++,
					optionName: `${index}`
				}
				years.push(element);
			}
		} else {
			for (let index = currentYear; index <= yearTo; index++) {
				const element = {
					id: id++,
					optionName: `${index}`
				}
				years.push(element);
			}
		}
	} else {
		for (let index = minYear; index <= maxYear; index++) {
			const element = {
				id: id++,
				optionName: `${index}`
			}
			years.push(element);
		}
	}

	return years;
}

export const talentStatus = [
	1000900005,
	1000900006
];

export const candidateStatus = [
	1000900001,
	1000900002,
	1000900003,
	1000900004,
	1000900007,
	1000900008,
	1000900009,
	1000900010,
	1000900011,
	1000900012,
	1000900013
];

export const candidateFormsDesfase = 10;

export const newSkill = {
    name: '',
    category: selectEmtpy,
    isForAllLanguages: false,
    isPublic: true,
    traductions: []
}