import Axios from 'axios';

const apiUrl = process.env.REACT_APP_JOB_BOARD_API;

const mainContext = 'talent';
const module = 'previous_job';

export const newPreviouJob = (talentId, workExperience, userId) => Axios.post(`${apiUrl}${mainContext}/${talentId}/${module}/new?userId=${userId}`, workExperience);

export const editPreviouJob = (talentId, workExperience, userId) => Axios.put(`${apiUrl}${mainContext}/${talentId}/${module}/edit?userId=${userId}`, workExperience);

export const getPreviousJobs = talentId => Axios.get(`${apiUrl}${mainContext}/${talentId}/${module}/`);

export const deletePreviouJob = (talentId, id, userId) => Axios.delete(`${apiUrl}${mainContext}/${talentId}/${module}/${id}/delete?userId=${userId}`);