import { useState, useEffect, useContext } from 'react';
import styles from "./password_recovery.module.scss";
import stylesHome from '../home/home.module.scss';
import Header from "../../components/header/header"
import RecoveryForm from "./components/recoveryForm"
import VerificationCode from './components/verificationCode';
import { AppContext } from '../candidates/context/AppProvider';

const PasswordRecovery = () => {

    const appContext = useContext(AppContext)
    const [recoveryInfo, setRecoveryInfo] = useState({})
    const [stepIndex, setStepIndex] = useState(0)

    const handleCancelValidation = () => {
        setRecoveryInfo({});
        setStepIndex(0);
    }

    const pageSteps = {
        0: <RecoveryForm
            recoveryInfo={ recoveryInfo }
            setRecoveryInfo={ setRecoveryInfo }
            setStepIndex={ setStepIndex}
            appContext={ appContext } />,
        1: <VerificationCode
            recoveryInfo={ recoveryInfo } 
            setStepIndex={ setStepIndex }
            setRecoveryInfo={ setRecoveryInfo }
            handleCancelValidation={ handleCancelValidation }
            appContext={ appContext } />
    }

    const codeRecoveryErrorMessage = () => {
        return (
            <div className={ appContext.isDesktopOrLaptop ? styles.error_modal : styles.error_modal_mobile }>
                <div className={ styles.wrapper }>
                    <p className={ styles.title }>{appContext.t('recovery.modal.title')}</p>
                    <p>{appContext.t('recovery.modal.instruction')}</p>
                    <div className={ styles.buttons }>
                        <button onClick={() => appContext.setModalFlag(false)}>{appContext.t('recovery.modal.labelBtn')}</button>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        appContext.setModalContent(codeRecoveryErrorMessage);
    }, [])

    const renderLoading = () => appContext.loadingData.flag && (
        <div className={ stylesHome.loading_container }>
            <div className={ stylesHome.spinner_container }>
                <div className={ stylesHome.spinner }></div>
                <p>{ appContext.loadingData.loadingLabel }</p>
            </div>
        </div>
    )

    return (
        <div 
            className = { 
                appContext.modalFlag ? 
                ( ( appContext.isDesktopOrLaptop ? styles.modal_wrapper : styles.modal_wrapper_mobile ) + " " + styles.modal_active) 
                : ( appContext.isDesktopOrLaptop ? styles.modal_wrapper : styles.modal_wrapper_mobile ) 
            }
        >
            { renderLoading() }
            { appContext.modalFlag && appContext.modal }
            <Header />
            <div className={ styles.pass_recovery}>
                {
                    appContext.notification
                }
                <div className={ styles.wrapper }>
                    {
                        pageSteps[stepIndex]
                    }
                </div>
            </div>
        </div>
    )
}

export default PasswordRecovery