import { useParams } from 'react-router-dom';
import { downloadCv } from '../../api/talentInfoClient';
import styles from './previewer.module.scss';
import { useEffect, useState } from 'react';

export const PdfPreviewer = () => {

    const { talentId } = useParams();

    const [url, setUrl] = useState();

    const getCv = () => {
        downloadCv(talentId).then((response) => {
            setUrl(window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf' })));
        }).catch(error => {
            console.log(error);
        })
    }

    useEffect(() => {
        if( talentId ) {
            getCv();
        }
    }, [])
    

    return (
        <iframe className={ styles.container } title="pdf" src={ url }></iframe>
    )
}
