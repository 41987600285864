export const currentYear = new Date().getFullYear()
export const selectEmtpy = { id: 0, description: '' };

export const MONTHS = [
	{ id: 1, optionName: "Enero"},
	{ id: 2, optionName: "Febrero"},
	{ id: 3, optionName: "Marzo"},
	{ id: 4, optionName: "Abril"},
	{ id: 5, optionName: "Mayo"},
	{ id: 6, optionName: "Junio"},
	{ id: 7, optionName: "Julio"},
	{ id: 8, optionName: "Agosto"},
	{ id: 9, optionName: "Septiembre"},
	{ id: 10, optionName: "Octubre"},
	{ id: 11, optionName: "Noviembre"},
	{ id: 12, optionName: "Diciembre"},
];

const year = {
	min: 1940,
	max: 2050
}

export const yearsOrder = {
	asc: 'asc',
	desc: 'desc',
	full: 'full'
}

export const yearsHardCode = order => {
	const years = [];
	let id = 1;
	if( order === yearsOrder.desc ) {
		for (let index = currentYear; index >= year.min; index--) {
			const element = {
				id: id++,
				optionName: `${index}`
			}
			years.push(element);
		}
	} else if ( order === yearsOrder.asc ) {
		for (let index = currentYear; index <= year.max; index++) {
			const element = {
				id: id++,
				optionName: `${index}`
			}
			years.push(element);
		}
	} else if ( order === yearsOrder.full ) {
		for (let index = year.min; index <= year.max; index++) {
			const element = {
				id: id++,
				optionName: `${index}`
			}
			years.push(element);
		}
	} else {
		console.log(order, 'not supported!');
	}
	return years;
}

export const hardSkillsCatalogId=1001100001
export const softSkillsCatalogId=1001100002
export const workSkillsCatalogId=1001100003
export const softwareCatalogId=1001100004