import PropTypes from 'prop-types';
import styles from './input.module.scss';
import Select, { selectPropType } from './Select';

export const SelectRange = ({
    prop1,
    prop2,
    disabled = false
}) => {
    return (
        <div className={ styles.error_container }>
            <div className={ styles.error_container_range_line }>
                <Select
                    name={ prop1.name }
                    onChange={ prop1.onChange }
                    options={ prop1.options }
                    placeholder={ prop1.placeholder }
                    value={ prop1.value }
                    error={ prop1.error }
                    disabled={ disabled }
                    index={ prop1.index }
                />
                <Select
                    name={ prop2.name }
                    onChange={ prop2.onChange }
                    options={ prop2.options }
                    placeholder={ prop2.placeholder }
                    value={ prop2.value }
                    error={ prop2.error }
                    disabled={ disabled }
                    index={ prop2.index }
                />
            </div>
            { (prop1.error || prop2.error) && (<p className={ styles.error_message_font }>{ prop1.error ? prop1.error : ( prop2.error ? prop2.error : '' ) }</p>) }
        </div>
    )
}

SelectRange.propTypes = {
    prop1: PropTypes.shape(selectPropType).isRequired,
    prop2: PropTypes.shape(selectPropType).isRequired,
    diabled: PropTypes.bool
};