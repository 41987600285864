import { selectEmtpy, yearsOrderAsc } from "./hardcode";
import { objEquals } from "./helper";

import i18next from "../../i18n";

export const validateEmail = value => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(value)) {
        return[i18next.t('validator.mail')]
    }
}

export const validateNotNull = value => {
    if (!value || value === '') {
        return [i18next.t('validator.required')]
    }
}

export const validateIsAlpha = value => {
    const regex=/^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/;
    if (!regex.test(value)) {
        return[i18next.t('validator.alphanumeric')]
    }
}

export const validatePhoneIsNumber = value => {
    const regex=/^[\d]+$/;
    if (!regex.test(value)) {
        return[i18next.t('validator.phone')]
    }
}

export const validateDateFormat = value => {
    const regex=/^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/;
    if( value && !regex.test(value) ) {
        return[i18next.t('validator.dateformat')]
    }
}

export const validateDate = value => {
    if( !isNaN(new Date(value) ) ) {
        return[i18next.t('validator.dateformat')]
    }
}

export const validateRangeDates = (startDateStr, endDateStr) => {
    if( startDateStr && endDateStr ) {
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);
        if( startDate > endDate ) {
            return[i18next.t('validator.daterange')]
        }
    }
}

export const validateIsChecked = value => {
    if(!value) {
        return[i18next.t('validator.required')]
    }
}

export const validateMinLength = value => {
    if(!value  || value.length < 8) {
        return[i18next.t('validator.pswd.minlen')]
    }
}

export const validateMinPhoneLength = value => {
    if(!value  || value.length < 10) {
        return[i18next.t('validator.phone')]
    }
}

export const validateLowerCharacter = value => {
    const regex=/[a-z]/;
    if(!value ||  !regex.test(value)) {
        return[i18next.t('validator.pswd.lowchar')]
    }
}

export const validateUpperCase = value => {
    const regex=/[A-Z]/;
    if(!value ||  !regex.test(value)) {
        return[i18next.t('validator.pswd.upperchar')]
    }
}

export const validateNumber = value => {
    const regex=/\d/;
    if(!value ||  !regex.test(value)) {
        return[i18next.t('validator.pswd.number')]
    }
}

export const validateSelectRangeDate = (start, end, order) => {
    if( start && end ) {
        let monthInvalid = true;
        let isYearValid = (order === yearsOrderAsc) ? true : ( end.yearId <= start.yearId );
        if( isYearValid ) {
            const isSameYear = start.yearId === end.yearId;
            monthInvalid = isSameYear && start.monthId > end.monthId;
        }
        if( monthInvalid ) {
            return[i18next.t('validator.daterange')]
        }
    }
}

export const validateTwoObjects = (value1, value2, msgError) => {
    if(objEquals(value1, value2)) {
        return[msgError];
    }
}

export const validateIsSelected = value => {
    const msgError = i18next.t('validator.required');
    return (!value.id && value.id !== 0) ? [msgError] : validateTwoObjects(value, selectEmtpy, msgError);
}