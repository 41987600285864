// Reference: https://react.i18next.com/latest/using-with-hooks

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: 'en',
    debug: false,
    // lng: "en", //Set manually language
    interpolation: {
        escapeValue: false,
    }
});

export default i18n;

/*
    If you need to access the t function or the i18next instance from outside of a React component
    you can simply import your ./i18n.js and use the exported i18next instance:

    Copy
    import i18next from './i18n'

    i18next.t('my.key')
 */