import { useContext, useEffect, useState } from 'react'
import styles from '../talents/talentInfo.module.scss'
import { AppContext } from '../candidates/context/AppProvider'
import { Searcher } from '../../components/talent-module/searcher/Searcher';
import moment from 'moment';
import { InfoMessage, InfoMessageType } from '../talents/components/misc/InfoMessage';
import { Select } from '../../components/talent-module/searcher/Select';
import { defaultStringEmpty, getFilterDate } from '../../core/talent-module/helper';
import { Loading } from '../../components/talent-module/loading/Loading';
import { Pagination } from '../../components/talent-module/pagination/Pagination';
import reloadIcon from '../../../assets/icons/reload.svg';
import { ReferralDetail } from './components/ReferralDetail';
import { getReferrals } from '../../api/vacancyClient';

export const ReferralsList = () => {

    const appContext = useContext(AppContext);
    const [modal, setModal] = useState();
    const [referrals, setReferrals] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [showReload, setShowReload] = useState(false);
    
    const fetchReferrals = (filter, currentPage, dateFilter) => {
        setTotal(0);
        setReferrals([]);
        setModal(<Loading />);
        getReferrals(filter, dateFilter, currentPage).then(response => {
            setModal(null);
            if( response.status === 200 && response.data && response.data.code === 200 && response.data.result && response.data.result.content && Array.isArray(response.data.result.content) ) {
                setReferrals(response.data.result.content);
                setTotalPages(response.data.result.totalPages);
                setTotal(response.data.result.totalElements);
                setShowReload(response.data.result.totalElements === 0 || filter !== '' || dateFilter);
            }
        }).catch(error => {
            console.log(error);
            setModal(null);
        })
    }
    
    useEffect(() => {
        fetchReferrals(appContext.filter, appContext.currentPage, appContext.dateFilter);
    }, [appContext.filter, appContext.currentPage, appContext.dateFilter]);
    
    const onClickDetail = referral => {
        appContext.contentScrollUp();
        appContext.setMenuNavigation([...appContext.menuNavigation, { label: defaultStringEmpty(referral.referredName)}]);
        appContext.setHomeContent(<ReferralDetail referralInput={ referral } />)
    }
      
    const onKeyDownSearch = ({key}) => {
        const isEmpty = key === 'Backspace' && appContext.filter.length === 1;
        if ( key === 'Enter' || isEmpty ) {
            setShowReload(!isEmpty);
            onClickSearch(isEmpty ? '' : appContext.filter, appContext.dateFilter);
        }
    }
    
    const onChangeSearch = ({target}) => {
        appContext.setFilter(target.value);
    }
      
    const onClickSearch = (filter, dateFilter) => {
        appContext.setCurrentPage(0);
        fetchReferrals(filter, 0, dateFilter);
    }
    
    const reload = () => {
        setShowReload(false);
        appContext.cleanFilter();
        onClickSearch('', null);
    }
    
    const onClickBackPage = () => {
        if( appContext.currentPage > 0 ) {
            appContext.setCurrentPage(appContext.currentPage - 1);
            fetchReferrals(appContext.filter, appContext.currentPage - 1, appContext.dateFilter);
        }
    }
    
      const onClickForwardPage = cP => {
        if( appContext.currentPage + cP < totalPages ) {
            appContext.setCurrentPage(appContext.currentPage + 1);
            fetchReferrals(appContext.filter, appContext.currentPage + 1, appContext.dateFilter);
        }
      }
    
      const renderSelectsFilter = () => (
        <div className={ styles.container_list_sel }>
            <Select
              name='dateFilter'
              value={ appContext.dateFilter }
              setValue={ appContext.setDateFilter }
              options = { getFilterDate() }
              placeholder = { appContext.t('candidate.referrel.list.headers.date') }
            />
        </div>
      )
    
      const renderNotFound = () => {
        let title = '';
        let subtitles = [];
        let cssParagraph = '';
        let type = null;
        if( referrals.length === 0 && appContext.filter === '' && !appContext.dateFilter ) {
          title = appContext.t('candidate.referrel.list.notFound');
          subtitles.push(appContext.t('candidate.notFound.subtitle'));
          subtitles.push(appContext.t('candidate.notFound.detail'));
          type = InfoMessageType.notFound;
          cssParagraph = styles.pharagrap_multiple;
        } else if (referrals.length === 0 && (appContext.filter !== '' || appContext.dateFilter )) {
          title = appContext.t('notMatch.title');
          subtitles.push(appContext.t('notMatch.subtitle'));
          type = InfoMessageType.notMatch;
          cssParagraph = styles.pharagrap_simple;
        }
        return <InfoMessage title={ title } subtitles={ subtitles } type={ type } cssParagraph={ cssParagraph } shadow />
      }
    
    const renderItems = () => referrals.map( (referral, index) => (
        <tr key={ index } onClick={ () => onClickDetail(referral) }>
            <td className={ styles.first_child_border }>
                <div className={ styles.td_column }>
                    <label className={ styles.name }>{ defaultStringEmpty(referral.referredName) }</label>
                </div>
            </td>
            <td>{ defaultStringEmpty(referral.vacancyName) }</td>
            <td>{ defaultStringEmpty(referral.referredPhone) }</td>
            <td>{ defaultStringEmpty(referral.referredEmail) }</td>
            <td className={ styles.last_child_border }>{ moment(referral.createdDate).format('DD/MM/YYYY') }</td>
        </tr>
    ));
    
    const renderTable = () => (
        <table>
            <thead>
                <tr>
                    <th className={ `${styles.first_child_border} ${styles.textleft}` } style={{ width: '30%' }}>{appContext.t('candidate.referrel.list.headers.name')}</th>
                    <th className={ styles.textleft } style={{ width: '30%' }}>{appContext.t('candidate.referrel.list.headers.vacancy')}</th>
                    <th className={ styles.textleft } style={{ width: '10%' }}>{appContext.t('candidate.referrel.list.headers.phone')}</th>
                    <th className={ styles.textleft } style={{ width: '20%' }}>{appContext.t('candidate.referrel.list.headers.email')}</th>
                    <th className={ `${styles.last_child_border} ${styles.textleft}` } style={{ width: '10%' }}>{appContext.t('candidate.referrel.list.headers.date')}</th>
                </tr>
            </thead>
            <tbody>
                { renderItems() }
            </tbody>
        </table>
    )

    const buildLabelResult = () => {
        const entityString = appContext.t('candidate.referrel.list.labelTotal').replace('s', total === 1 ? '' : 's');
        let complementString = appContext.filter !== '' || appContext.dateFilter ? appContext.t('candidate.list.labelNoFound') : '';
        complementString = complementString.replace('s', total === 1 ? '' : 's');
        return `${entityString} ${complementString}`;
    }

    return (
        <div className={ styles.container } >
            <div className={ styles.wrapper }>
                <label className={ styles.title }>{ appContext.t('menu.candidate.opt2') }</label>
                <div className={ styles.container_list_btn }>
                    <div className={ styles.btn_label }>
                        <div className={ styles.width_40 }>
                            <Searcher
                                name={'filter'} 
                                value={ appContext.filter } 
                                placeholder={ appContext.t('candidate.referrel.list.labelFilter') }
                                onChange={ onChangeSearch }
                                onKeyDown={ onKeyDownSearch }
                                onClick={ () => onClickSearch(appContext.filter, appContext.dateFilter) }
                            />
                        </div>
                        <label>{ `${total} ${ buildLabelResult() }` }</label>
                        { showReload &&  <div className={ styles.separator } /> }
                        {
                        showReload && (
                            <div className={ styles.reload } onClick = { () => reload() }>
                                <img src={ reloadIcon } alt='' />
                                <label>{ appContext.t('candidate.referrel.list.labelReload') }</label>
                            </div>
                        )
                        }
                    </div>
                </div>
                { renderSelectsFilter() }
                { renderTable() }
                { !modal && referrals.length === 0 && renderNotFound() }
                { modal }
                <Pagination
                    totalElements={ referrals.length }
                    currentPage={ appContext.currentPage }
                    totalPages={ totalPages }
                    onClickBackPage={ onClickBackPage }
                    onClickForwardPage={ onClickForwardPage }
                />
            </div>
        </div>
    )
}
