import { useContext, useEffect, useState } from 'react'
import styles from '../../candidates/candidateInfo.module.scss'
import { AppContext } from '../../candidates/context/AppProvider'
import { defaultStringEmpty } from '../../../core/talent-module/helper';
import phoneIcon from '../../../../assets/icons/phone.svg';
import envelopeIcon from '../../../../assets/icons/envelope.svg';
import linkedinIcon from '../../../../assets/icons/linkedin.svg';
import personIcon from '../../../../assets/icons/person.svg';
import { getReferral } from '../../../api/vacancyClient';
import moment from 'moment';

export const ReferralDetail = ({
    referralInput
}) => {

    const appContext = useContext(AppContext);
    const [referral, setReferral] = useState(referralInput)

    const fetchReferral = () => {
        getReferral(referralInput.referrerEmail, referralInput.vacancyId).then(response => {
            if( response.status === 200 && response.data && response.data.code === 200 && response.data.result ) {
                setReferral(response.data.result);
            }
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
      fetchReferral();
    }, [referral])

    const renderMainSection = () => (
        <>
            <div className={ styles.section_header}>
                <div className={ styles.section_title }>
                    <div className={ styles.decorator }></div>
                    { appContext.t('candidate.referrel.detail.title') }
                </div>
            </div>
            <div className={ `${styles.section_header} ${styles.section_subtitle}` }>
                <label style={{ fontSize: '24px' }}>{ referral.referredName }</label>
            </div>
            <div className={ styles.section_header }>
                <label>{appContext.t('candidate.referrel.list.headers.vacancy')}: </label>{ defaultStringEmpty(referral.vacancyName) }
            </div>
            <div className={ styles.section_header }>
                <label>{appContext.t('candidate.referrel.list.headers.date')}: </label>{ moment(referral.createdDate).format('DD/MM/YYYY') }
            </div>
            <div className={ styles.section_header }>
                <label>{appContext.t('candidate.resume.info.phone')}:</label>
            </div>
            <div className={ styles.section_header }>
                <img src={ phoneIcon } alt="" />{ defaultStringEmpty(referral.referredPhone) }
            </div>
            <div className={ styles.section_header }>
                <img src={ envelopeIcon } alt="" />{ defaultStringEmpty(referral.referredEmail) }
            </div>
            <div className={ styles.section_header }>
                <img src={ linkedinIcon } alt="" />{ referral.socialNetworkLink ? referral.socialNetworkLink : appContext.t('noLinkedin') }
            </div>
            <div className={ `${styles.section_header} ${styles.section_subtitle}` }>
                <label>{appContext.t('candidate.referrel.detail.comments')}:</label>
            </div>
            <div className={ styles.section_header }>
                { referral.comment ? referral.comment : appContext.t('noComments')}
            </div>
        </>
    )

    const renderReferralSection = () => (
        <div className={ styles.section}>
            <div className={ styles.section_header}>
                <div className={ styles.section_title }>
                    <div className={ styles.decorator }></div>
                    { `${appContext.t('candidate.referrel.detail.title2')} ${referral.referredName}` }
                </div>
            </div>
            <div className={ `${styles.section_header} ${styles.section_subtitle}` }>
                <img src={ personIcon } alt="" />{ defaultStringEmpty(referral.referrerName) }
            </div>
            <div className={ styles.section_header }>
                <img src={ envelopeIcon } alt="" />{ defaultStringEmpty(referral.referrerEmail) }
            </div>
            <div className={ styles.section_header }>
                <img src={ phoneIcon } alt="" />{ defaultStringEmpty(referral.referrerPhone) }
            </div>
        </div>
    )

    return (
        <div className={ `${appContext.showMenu ? styles.main : styles.main_full}` } >
            <div className={ styles.candidate_info } >
                <div className={ styles.wrapper }>
                    <div className={ styles.candidate_resume }>
                        <div className={ styles.content_wrapper }>
                            <div className={ styles.personal_info }>
                                { renderMainSection() }
                                <hr />
                                { renderReferralSection() }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
