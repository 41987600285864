import PropTypes from 'prop-types';
import styles from './searcher.module.scss';
import {ReactComponent as SearchIcon} from '../../../../assets/icons/searcher_icon.svg';
import { useContext } from 'react';
import { AppContext } from '../../../pages/candidates/context/AppProvider';

export const Searcher = ({
    name,
    value,
    placeholder,
    maxLength = 50,
    onChange,
    onKeyDown,
    onClick
}) => {

    const appContext = useContext(AppContext);

    return (
        <div className={ styles.container }>
            <input type='text' autoComplete='off'
                name={name}
                placeholder={placeholder}
                value={value}
                maxLength={maxLength}
                onChange={ onChange }
                onKeyDown={ onKeyDown } />
            <div className={ styles.button } onClick={ onClick }>
                <SearchIcon />
                <label>{ appContext.t('search') }</label>
            </div>
        </div>
    )
}

Searcher.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired
}