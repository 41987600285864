import PropTypes from 'prop-types';
import styles from './input.module.scss';
import { isNum } from '../../core/helper';

export const QuantityRange = ({
    name,
    inputAlign = 'left',
    label,
    value,
    onChange,
    disabled = false,
    error
}) => {

    const onInputType = ({ target }) => {
        const name = target.name;
        const inputValue = target.value;
        if( inputValue !== '' && isNum(inputValue) && ( inputValue >= 0 && inputValue <= 999999999 ) ) {
            onChange(name, inputValue)
        } else if( inputValue === '' ) {
            onChange(name, '')
        }
    }

    const buildStyle = () => ({
        width: '100%',
        textAlign: inputAlign
    })

    return (
        <div className={ styles.error_container }>
            <div className={ styles.error_container_range_line }>
                { label.range1 }
                <input
                    name={ name.range1 }
                    autoComplete='off'
                    style={ buildStyle() }
                    placeholder='0.00'
                    onChange={ onInputType }
                    value={ value.range1 }
                />
                { label.range2 }
                <input
                    name={ name.range2 }
                    autoComplete='off'
                    style={ buildStyle() }
                    placeholder='0.00'
                    onChange={ onInputType }
                    value={ value.range2 }
                />
            </div>
            { error && (<p className={ styles.error_message_font }>{ error }</p>) }
        </div>
    )
}

QuantityRange.propTypes = {
    name: PropTypes.shape({
		range1: PropTypes.string.isRequired,
		range2: PropTypes.string.isRequired,
	}),
    inputAlign: PropTypes.string,
    label: PropTypes.shape({
		range1: PropTypes.string.isRequired,
		range2: PropTypes.string.isRequired,
	}),
    value: PropTypes.shape({
		range1: PropTypes.string,
		range2: PropTypes.string,
	}),
    diabled: PropTypes.bool
};