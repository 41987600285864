import { 
    useState } from 'react';

import styles from '../password_recovery.module.scss';
import stylesCandidate from '../../candidates/candidateInfo.module.scss';
import resendIcon from '../../../../assets/icons/arrows_circle_orange.svg';

import Axios from 'axios';

import {ReactComponent as ValidationIcon} from '../../../../assets/icons/check_gray.svg';

import { 
    validateMinLength,
    validateNotNull,
    validateLowerCharacter,
    validateUpperCase,
    validateNumber } from '../../../core/validators';
import { InputPassword } from '../../../components/inputs/InputPassword';


const VerificationCode = (props) => {

    const apiUrl = process.env.REACT_APP_API_LOGIN_URL;
    const passwordRecoveryNotificationApiURl = process.env.REACT_APP_API_URL;

    const { recoveryInfo, handleCancelValidation, setRecoveryInfo, appContext } = props;
    const [validationErrors, setValidationErrors] = useState({});
    const [requestFlag, setRequestFlag] = useState(false);
    const [codeInfo, setCodeInfo] = useState({});

    const validationFields = {
        password: [validateLowerCharacter, validateUpperCase, validateNumber, validateMinLength, validateNotNull],
        passwordConfirmation: [validateNotNull]
    }

    const codeInputs = [
        <input className={ Object.keys(validationErrors).includes("code") ? styles.error : undefined  } id="code" key={1} name="0" maxLength="1" value={ codeInfo[0] || "" }/>,
        <input className={ Object.keys(validationErrors).includes("code") ? styles.error : undefined  } id="code1" key={2} name="1" maxLength="1" value={ codeInfo[1] || "" }/>,
        <input className={ Object.keys(validationErrors).includes("code") ? styles.error : undefined  } id="code11" key={3} name="2" maxLength="1" value={ codeInfo[2] || "" }/>,
        <input className={ Object.keys(validationErrors).includes("code") ? styles.error : undefined  } id="code111" key={4} name="3" maxLength="1" value={ codeInfo[3] || "" }/>,
        <input className={ Object.keys(validationErrors).includes("code") ? styles.error : undefined  } id="code1111" key={5} name="4" maxLength="1" value={ codeInfo[4] || "" }/>,
        <input className={ Object.keys(validationErrors).includes("code") ? styles.error : undefined  } id="code11111" key={6} name="5" maxLength="1" value={ codeInfo[5] || "" }/>
    ]

    const handleCodeChange = event => {
        if(event.nativeEvent.data === null) {
            setCodeInfo((prevData) => ({...prevData, [event.target.name]: ""}))
        }
        if(event.target.value.match(/^[0-9]$/)){
            setCodeInfo((prevData) => ({...prevData, [event.target.name]: event.target.value}))
            // actualizando el focus
            if (event.target.id !== "code11111") {
                document.getElementById(event.target.id + 1).focus();
            }
        }
    }

    const validateRequest = () => {
        const errorObject = {};
        // validate code inputs
        if(Object.keys(codeInfo).filter((index) => {
            if(codeInfo[index] !== "") {
                return codeInfo[index]
            }
        }).length < 6) {
            errorObject["code"] = [appContext.t('validator.required')]
        };

        // validate password confirmation
        if((recoveryInfo.password && recoveryInfo.passwordConfirmation) && (recoveryInfo.password !== recoveryInfo.passwordConfirmation)) {
            errorObject["password"] = [""]
            errorObject["passwordConfirmation"] = [appContext.t('validator.pswd.match')]
        }

        // validate password inputs
        var helper;
        for(const field in validationFields) {
            for (const validator in validationFields[field]) {
                const validationFunction = validationFields[field][validator]; 
                helper = validationFunction(recoveryInfo[field]);
                if (helper) {
                    errorObject[field] = validationFunction === validateNotNull ? helper : [appContext.t('validator.pswd.general')];
                }
            }
        }

        return errorObject
    }

    const handleSubmit = () => {
        setRequestFlag(true);
        const validationErrors = validateRequest();
        if (Object.keys(validationErrors).length === 0) {
            const request = {
                "username": recoveryInfo.username,
                "code": Object.keys(codeInfo).map((index) => codeInfo[index]).join(''),
                "password": recoveryInfo.password
            }
            appContext.showLoading(true, appContext.t('loading'), stylesCandidate.no_scroll);
            Axios.put(apiUrl + "updatePassword", request).then((response) => {
                appContext.showLoading(false, '', stylesCandidate.no_scroll);
                if (response.data.code === 153) {
                    setValidationErrors({
                        code: [appContext.t('recovery.vcwrong')]
                    })
                    return
                } 
                appContext.displayNotification(appContext.t('recovery.success'));
                appContext.navigate("/login");
            }).catch(error => {
                appContext.showLoading(false, '', stylesCandidate.no_scroll);
                if( error.code === 'ERR_NETWORK' ) {
                    appContext.displayNotification(null, true, true);
                } else if(error.response.status === 500) {
                    setValidationErrors({
                        code: [appContext.t('recovery.vcwrong')]
                    })
                }
            })
        } else {
            setValidationErrors(validationErrors);
        }
    }

    const handleResendCode = () => {
        appContext.showLoading(true, appContext.t('loading'), stylesCandidate.no_scroll);
        Axios.get(`${passwordRecoveryNotificationApiURl}auth/signUp/resendVerifyCode?username=${recoveryInfo.username}`).then((response) => {
            appContext.showLoading(false, '', stylesCandidate.no_scroll);
            if (response.data.code === 151) {
                appContext.setModalFlag(true);
                return
            } 
            appContext.displayNotification(appContext.t('recovery.vcsent'));
        }).catch(error => {
            appContext.showLoading(false, '', stylesCandidate.no_scroll);
            if( error.code === 'ERR_NETWORK' ) {
                appContext.displayNotification(null, true, true);
            } else if(error.response.status === 500 && (error.response.data.code === 150)) {
                appContext.setModalFlag(true);
            }
            console.log(error);
        })
    }

    const handleFormChange = ({target}) => {
        setRecoveryInfo((prevData) => ({...prevData, [target.name]: target.value}))
    }

    const renderSuggestionsMobile = (value1, value2) => {
        const val1 = validateMinLength(value1) || validateMinLength(value2); 
        const val2 = validateLowerCharacter(value1) || validateLowerCharacter(value2);
        const val3 = validateUpperCase(value1) || validateUpperCase(value2);
        const val4 = validateNumber(value1) || validateNumber(value2);
        return !appContext.isDesktopOrLaptop && (
            <div className={ `${styles.password_validator} ${styles.active}` }>
                <div className={ styles.validator_row }>
                    <p className={ styles.validator_title }>{appContext.t('recovery.pswdMust')}</p>
                </div>
                <div className={ styles.validator_row  +  " " + (!val1 ? styles.validated : undefined)  }>
                    <ValidationIcon />
                    <p>{appContext.t('recovery.suggestions.minlen')}</p>
                </div> 
                <div className={ styles.validator_row +  " " + (!val2 ? styles.validated : undefined) }>
                    <ValidationIcon />
                    <p>{appContext.t('recovery.suggestions.lowchar')}</p>
                </div>
                <div className={ styles.validator_row +  " " + (!val3 ? styles.validated : undefined) }>
                    <ValidationIcon />
                    <p>{appContext.t('recovery.suggestions.upperchar')}</p>
                </div>
                <div className={ styles.validator_row +  " " + (!val4 ? styles.validated : undefined) }>
                    <ValidationIcon />
                    <p>{appContext.t('recovery.suggestions.number')}</p>
                </div>
            </div>
        )
    }

    return (
        <div className={ styles.verification_wrapper }>
            <p className={ styles.title }>{appContext.t('recovery.vctitle')}</p>
            <div className={ `${styles.instructions_wrapper} ${styles.small_gap}` }>
                <p>{appContext.t('recovery.vcInst01')} <b>{ recoveryInfo.username }</b>,</p>
                <p>{appContext.t('recovery.vcInst02')}</p>
            </div>
            <div className={ styles.code_wrapper }>
                <p className={ styles.code_label }>{appContext.t('recovery.labelVc')}</p>
                <div className={ styles.code_row } onChange={(event) => handleCodeChange(event) }>
                    { codeInputs.map((codeInput) => codeInput) }
                </div>
                {
                    Object.keys(validationErrors).includes("code") && (
                        <p className={ styles.error_message }>{ validationErrors.code[0] }</p>
                    )
                }
            </div>
            <button className={ styles.resend_button } onClick={ () => handleResendCode() } >
                <img src={ resendIcon } alt="" />
                {appContext.t('recovery.labelResend')}
            </button>
            <div className={ styles.instructions_wrapper }>
                <form>
                    <InputPassword
                        isShort = { true }
                        name = { 'password' }
                        label = { appContext.isDesktopOrLaptop ? appContext.t('recovery.labelNewPswd') : appContext.t('login.pswd') }
                        value = { recoveryInfo.password }
                        msgError = { Object.keys(validationErrors).includes("password") ? validationErrors.password[0] : undefined }
                        onChange = { handleFormChange }
                        requestFlag = { requestFlag }
                        arrowBottom = { 50 }
                    />
                    <InputPassword
                        isShort = { true }
                        name = { 'passwordConfirmation' }
                        label = { appContext.t('recovery.labelRepPswd') }
                        value = { recoveryInfo.passwordConfirmation }
                        msgError = { Object.keys(validationErrors).includes("passwordConfirmation") ? validationErrors.passwordConfirmation[0] : undefined }
                        onChange = { handleFormChange }
                        requestFlag = { requestFlag }
                        bottom = { Object.keys(validationErrors).includes("passwordConfirmation") ? 20 : -15 }
                        arrowBottom = { 20 }
                    />
                </form>
                { renderSuggestionsMobile(recoveryInfo.password, recoveryInfo.passwordConfirmation) }
            </div>
            <div className={ styles.buttons_wrapper}>
                <button onClick={ () => handleCancelValidation() }>{appContext.t('recovery.labelChgMail')}</button>
                <button className={ styles.active } onClick={ () => handleSubmit() }>{ appContext.isDesktopOrLaptop ? appContext.t('recovery.labelChgPswd') : appContext.t('continue') }</button>
            </div>
        </div>
    )
}
export default VerificationCode;