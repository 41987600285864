import { useContext } from 'react';
import { AppContext } from '../../context/AppProvider';
import styles from '../../candidateInfo.module.scss';
import editIcon from '../../../../../assets/icons/orange_pencil.svg';

export const AcademicInfoResume = (
    {
        personalInfo,
        updateView
    }
) => {

    const appContext = useContext(AppContext);
    
    return (
        <div className={ styles.section }>
            <div className={ styles.section_header}>
                <div className={ styles.section_title }>
                    <div className={ styles.decorator }></div>
                    { appContext.t('candidate.resume.academic.title') }
                </div>
                <div className={ styles.button_container }>
                    <div className={ styles.vertical_separator }></div>
                    <button className={ styles.add_info } onClick={() => updateView(2, appContext.t('candidate.resume.academic.title'))}>
                        { appContext.t('candidate.resume.edit') }
                        <img src={ editIcon } alt="" />
                    </button>
                </div>
            </div>
            <div className={ styles.cards_wrapper }>
                {
                    personalInfo.educations?.map((educationInfo, index) => {
                        //const periodStart = educationInfo.startDate ? getParsedDate(educationInfo.startDate) : appContext.t('noData.unspec');
                        //const periodEnd = educationInfo.endDate ? getParsedDate(educationInfo.endDate) : appContext.t('noData.inProg');
                        //const period = periodStart === appContext.t('noData.unspec') && periodEnd === appContext.t('noData.inProg') ? appContext.t('candidate.resume.courses.notPeriod') : `${periodStart} - ${periodEnd}`;
                        return(
                            <div key={ index } className={ styles.info_card }>
                                <div className={ styles.card_title}>
                                    <label>{ educationInfo.name }</label>
                                    <label className={ styles.hidden_mobile }>-</label>
                                    <label>{(educationInfo.school || appContext.t('noData.notset')) }</label>
                                </div>
                                <p className={ styles.info_date }>{ educationInfo.period }</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}