import {
    useEffect,
    useRef, 
    useContext,
    useState} from "react";
import styles from '../../candidateInfo.module.scss';
import stylesM from '../../../../components/modal/modal_base.module.scss';
import profileIcon from '../../../../../assets/icons/icon_photo.svg';
import profilePlusIcon from '../../../../../assets/icons/icon_photo_plus.svg';
import pencilIcon from '../../../../../assets/icons/orange_pencil.svg';
import { getTalent } from "../../../../api/talentClient";
import { AppContext } from "../../context/AppProvider";
import { CandidateContext } from "../../context";
import { InputText } from "../../../../components/inputs/InputText";
import { TextArea } from "../../../../components/inputs/TextArea";
import Select, { SelectSortType } from "../../../../components/inputs/Select";
import { selectEmtpy } from "../../../../core/hardcode";
import { QuantityRange } from "../../../../components/inputs/QuantityRange";
import { Seniority } from "../../../../components/inputs/Seniority";

const PersonalInfo = ({
    countries,
    catCurrencies,
    catSeniority,
    catSkillProfile,
    personalInfo,
    setPersonalInfo,
    personalInfoErrors,
    file,
    setFile,
    descriptionFocus,
    seniorityFocus,
    photoError,
    displayNotification,
    candidateMode
}) => {
    
    const appContext = useContext(AppContext);
    const { setPersonalInfoCache } = useContext(CandidateContext);
    const [optionsSelecteds, setOptionsSelecteds] = useState([]);

    const hiddenFileInput = useRef(null);
    const photoRef = useRef(null);

    const getUserInfo = () => {
        appContext.showLoading(true, appContext.t('loading'), styles.no_scroll);
        getTalent(appContext.talent.id).then((response) => {
            const result = response.data.result;
            const countryFind = result.address && result.address.country && countries && countries.length > 0 && countries.find( c => c.optionName === result.address.country);
            const currencyFind = catCurrencies.find( c => c.id === ( result.currency ? result.currency : 1003300001 ) );
            const profileFind = catSkillProfile.find( c => c.id === result.profileId );
            const personalInfo = {
                firstName: result.firstName,
                lastName: result.lastName,
                position: result.position,
                description: result.description,
                phone: result.phone,
                email: result.email,
                countryName: result.address?.country,
                country: countryFind ? { id: countryFind.id, description: countryFind.optionName } : selectEmtpy,
                state: result.address?.state,
                imgProfile: result.imgProfile,
                previousJobs: result.previousJobs,
                educations: result.educations,
                courses: result.courses,
                languages: result.languages,
                skills: result.skills,
                statusName: result.statusName,
                currency: currencyFind ? { id: currencyFind.id, description: currencyFind.optionName } : undefined,
                salaryMin: result.salaryMin,
                salaryMax: result.salaryMax,
                seniority: result.jobSeniorityId,
                profile: profileFind ? { id: profileFind.id, description: profileFind.optionName } : selectEmtpy,
                profileId: result.profileId
            };
            setPersonalInfo(personalInfo);
            setPersonalInfoCache(personalInfo);
            setOptionsSelecteds([result.profileId]);
            appContext.showLoading(false, '', styles.no_scroll);
        }).catch( error => {
            console.log(error);
            if( error.code === 'ERR_NETWORK' ) {
                displayNotification(null, false, false, true);
            }
            appContext.showLoading(false, '', styles.no_scroll);
        })
    }

    useEffect(() => {
        if (appContext.talent.id) {
            getUserInfo();
        } else {
            setPersonalInfoCache({});
        }
        if ( photoError ) {
            photoRef.current.focus();
        }
    }, [])

    const onChangeInput = (property, value) => {
        setPersonalInfo({ ...personalInfo, [property]: value});
    }

    const onChangeSelect = (property, value) => {
        setPersonalInfo({ ...personalInfo, [property]: value, [`${property}Id`]: value.id, [`${property}Name`]: value.description});
        if (property === 'profile' && value && value.id && value.id !== 0) {
            setOptionsSelecteds([value.id])
        }
    }

    const handleFileInputChange = ({ target }) => {
        const fileUploaded = target.files[0];
        setFile(fileUploaded);
    }

    const handlePictureClick = () => {
        hiddenFileInput.current.click();
    };

    const renderName = () => (
        <>
            <p className={ `${styles.form_section_title} ${styles.no_padding_top}` }>
                { appContext.t('candidate.resume.info.nameRecomendation') }
            </p>
            <div className={ styles.form_columns }>
                <InputText
                    cssContainer={ styles.form_column }
                    name={ 'firstName' }
                    label={ `${appContext.t('candidate.resume.info.name')}*` }
                    value={ personalInfo.firstName || "" }
                    onChange={ onChangeInput }
                    error={ Object.keys(personalInfoErrors).includes("firstName") ? personalInfoErrors.firstName[0] : '' }
                    marginError={ Object.keys(personalInfoErrors).includes("lastName") && !Object.keys(personalInfoErrors).includes("firstName") }
                />
                <InputText
                    cssContainer={ styles.form_column }
                    name={ 'lastName' }
                    label={ `${appContext.t('candidate.resume.info.lastname')}*` }
                    value={ personalInfo.lastName || "" }
                    onChange={ onChangeInput }
                    error={ Object.keys(personalInfoErrors).includes("lastName") ? personalInfoErrors.lastName[0] : '' }
                    marginError={ Object.keys(personalInfoErrors).includes("firstName") && !Object.keys(personalInfoErrors).includes("lastName") }
                />
            </div>
        </>
    )

    const renderPosition = () => (
        <>
            { candidateMode &&
                <div className={ styles.form_row} style={{marginBottom: '12px'}}>
                    <label>{ `${appContext.t('candidate.resume.info.position')} ${ appContext.t('candidate.resume.info.positionCandidate') }` }*</label>
                </div>
            }
            <div className={ styles.form_columns }>
                <InputText
                    cssContainer={ styles.form_column }
                    name={ 'position' }
                    label={ candidateMode ? null : appContext.t('candidate.resume.info.position') }
                    subLabel={ candidateMode ? appContext.t('candidate.resume.info.positionExample') : null }
                    value={ personalInfo.position || "" }
                    onChange={ onChangeInput }
                    error={ Object.keys(personalInfoErrors).includes("position") ? personalInfoErrors.position[0] : '' }
                />
                { !candidateMode &&
                    <div className={ styles.form_column }>
                        <div className={ stylesM.custom_select }>
                            <label>{ appContext.t('candidate.resume.info.competenceProfile') }</label>
                            <Select
                                name={ 'profile' }
                                placeholder={ appContext.t('candidate.resume.info.placeholderProfile') }
                                value={ personalInfo.profile || selectEmtpy }
                                sercheable
                                onChange={ onChangeSelect }
                                options={ catSkillProfile }
                                optionsSelecteds={ optionsSelecteds }
                                removedFromIcon={ () => setOptionsSelecteds([]) }
                                sort={ SelectSortType.ALPHA }
                            />
                            { Object.keys(personalInfoErrors).includes("position") && '\u00A0' }
                        </div>
                    </div>
                }
            </div>
        </>
    )

    const renderPictureComponent = () => (
        <>
            <p className={ styles.form_section_title }>{ appContext.t('candidate.resume.info.photo') }*</p>
            <p><b>{ appContext.t('candidate.resume.info.photoRecomendation') }</b></p>
            <label className={ styles.subtitle }>
                { appContext.t('candidate.resume.info.photoRestrictions') }
            </label>
            {
                Object.keys(personalInfoErrors).includes("imgProfile") && (
                    <p className={ styles.error_message }>{ personalInfoErrors.imgProfile[0] }</p>
                )
            }
            <div className={ styles.form_row }>
                <div className={ `${styles.image_container} ${(( Object.keys(personalInfoErrors).includes("imgProfile")) && styles.error)}` } onClick={handlePictureClick}>
                    <button ref={ photoRef }>
                        {
                            !file ? (
                                personalInfo.imgProfile ? (
                                <div className={ styles.image_content }>
                                    <div className={ styles.image_circle }>
                                        <img src={ `${personalInfo.imgProfile}?${new Date().getTime()}` } alt="" />
                                    </div>
                                    <div className={ styles.image_text }>
                                        <p>{ appContext.t('candidate.resume.info.photoEdit') }</p>
                                        <img src={ pencilIcon } alt="" />
                                    </div>
                                </div>
                                ) : (
                                <div className={ styles.image_content }>
                                    <div className={ styles.image_circle }>
                                        <img src={ profileIcon } alt="" />
                                    </div>
                                    <div className={ styles.image_text }>
                                        <p>{ appContext.t('candidate.resume.info.photoNew') }</p>
                                        <img src={ profilePlusIcon } alt="" />
                                    </div>
                                </div>
                                )
                            ) : (
                                <div className={ styles.image_content }>
                                    <div className={ styles.image_circle }>
                                        <img src={ URL.createObjectURL(file) } alt="" />
                                    </div>
                                    <div className={ styles.image_text }>
                                        <p>{ appContext.t('candidate.resume.info.photoEdit') }</p>
                                        <img src={ pencilIcon } alt="" />
                                    </div>
                                </div>
                            )
                        }
                    </button>
                </div>
                <input className={ styles.file_input } type="file" onChange={handleFileInputChange} ref={hiddenFileInput} accept="image/png, image/gif, image/jpeg" />
            </div>
            <p className={ `${styles.form_section_title} ${styles.label_blue}` }>{ appContext.t('candidate.resume.info.phone') }</p>
            <div className={ styles.form_columns }>
                <InputText
                    cssContainer={ styles.form_column }
                    name={ 'phone' }
                    label={ appContext.t('candidate.resume.info.phoneRecomendation') }
                    value={ personalInfo.phone || "" }
                    maxLength={ 10 }
                    onChange={ onChangeInput }
                    error={ Object.keys(personalInfoErrors).includes("phone") ? personalInfoErrors.phone[0] : '' }
                />
            </div>
        </>
    )

    const renderAboutYou = () => (
        <div className={ styles.form_row}>
            <TextArea
                cssSubtitle={ styles.subtitle }
                cssCounter={ styles.subtitle_left }
                name={ 'description' }
                label={ `${appContext.t('candidate.resume.info.aboutYou')}*` }
                sublabel={ appContext.t('candidate.resume.info.aboutYouRecomendation') }
                maxLength={ 1500 }
                errorLen={ 'validator.length' }
                value={ personalInfo.description || "" }
                onChange={ onChangeInput }
                error={ Object.keys(personalInfoErrors).includes("description") ? personalInfoErrors.description[0] : '' }
                focus={ descriptionFocus }
            />
        </div>
    )

    const renderLocation = () => (
        <>
            <p className={ `${styles.form_section_title} ${styles.no_padding_top} ${styles.label_blue}` }>
                { appContext.t('candidate.resume.info.location') }
            </p>
            <div className={ styles.form_columns }>
                <div className={ styles.form_column }>
                    <div className={ stylesM.custom_select }>
                        <label>{ `${appContext.t('candidate.resume.info.country')}*` }</label>
                        <Select
                            name={ 'country' }
                            placeholder={ appContext.t('candidate.resume.chooseOption') }
                            value={ personalInfo.country || selectEmtpy }
                            onChange={ onChangeSelect }
                            options={ countries }
                            error= { Object.keys(personalInfoErrors).includes("country") ? personalInfoErrors.country[0] : '' }
                        />
                        {
                            Object.keys(personalInfoErrors).includes("country") && (
                                <p className={ styles.error_message }>{ personalInfoErrors["country"][0] }</p>
                            )
                        }
                        { Object.keys(personalInfoErrors).includes("state") && !Object.keys(personalInfoErrors).includes("country") && '\u00A0' }
                    </div>
                </div>
                <InputText
                    cssContainer={ styles.form_column }
                    name={ 'state' }
                    label={ `${appContext.t('candidate.resume.info.state')}*` }
                    value={ personalInfo.state || "" }
                    onChange={ onChangeInput }
                    error={ Object.keys(personalInfoErrors).includes("state") ? personalInfoErrors["state"][0] : '' }
                    marginError={ Object.keys(personalInfoErrors).includes("country") && !Object.keys(personalInfoErrors).includes("state") }
                />
            </div>
        </>
    )

    const renderSalary = () => (
        <>
            <p className={ `${styles.form_section_title} ${styles.label_blue}` }>
                { appContext.t('candidate.resume.info.salary') }
            </p>
            <div className={ styles.form_columns }>
                <div className={ styles.form_column }>
                    <label>{ appContext.t('candidate.resume.info.quantity') }</label>
                    <QuantityRange
                        name={{
                            range1: 'salaryMin',
                            range2: 'salaryMax'
                        }}
                        inputAlign={ 'right' }
                        label={{
                            range1: appContext.t('candidate.resume.info.salaryFrom'),
                            range2: appContext.t('candidate.resume.info.salaryTo')
                        }}
                        value={{
                            range1: `${ personalInfo.salaryMin ? personalInfo.salaryMin : '' }`,
                            range2: `${ personalInfo.salaryMax ? personalInfo.salaryMax : '' }`
                        }}
                        onChange={ onChangeInput }
                        error= { Object.keys(personalInfoErrors).includes("salary") ? personalInfoErrors.salary[0] : '' }
                    />
                </div>
                <div className={ styles.form_column }>
                    <div className={ stylesM.custom_select }>
                        <label>{ appContext.t('candidate.resume.info.currency') }</label>
                        <Select
                            name={ 'currency' }
                            placeholder={ '' }
                            value={ personalInfo.currency ? personalInfo.currency : selectEmtpy }
                            onChange={ onChangeSelect }
                            options={ catCurrencies }
                        />
                        { Object.keys(personalInfoErrors).includes("salary") && '\u00A0' }
                    </div>
                </div>
            </div>
        </>
    )

    const renderSeniority = () => (
        <div className={ styles.form_columns}>
            <div className={ styles.form_column }>
                <Seniority
                    name='seniority'
                    label={`${ appContext.t('candidate.resume.info.seniority') }${ !candidateMode ? '*' : '' }`}
                    catSeniority={ catSeniority }
                    value={ personalInfo.seniority }
                    onChange={ onChangeInput }
                    error={ Object.keys(personalInfoErrors).includes("seniority") ? appContext.t('validator.seniority') : '' }
                    focus={ seniorityFocus }
                />
            </div>
        </div>
    )

    return (
        <div className={ styles.form_container }>
            <p className={ styles.title }>{ appContext.t('candidate.resume.info.title') }</p>
            <div className={ styles.form_wrapper }>
                { renderName() }
                { renderPosition() }
                { renderSeniority() }
                { renderAboutYou() }
                { renderPictureComponent() }
                { renderLocation() }
                { candidateMode && renderSalary() }
            </div>
        </div>
    )
}

export default PersonalInfo;