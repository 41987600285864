import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './input.module.scss';
import { borderError } from '../../core/helper';
import { AppContext } from '../../pages/candidates/context/AppProvider';

export const TextArea = ({
    cssSubtitle,
    cssCounter,
    name,
	label,
    sublabel,
    maxLength = 100,
    placeholder = '',
    value,
	onChange,
    error,
    errorLen = 'validator.length2',
    focus = false
}) => {

    const appContext = useContext(AppContext);

    const [counter, setCounter] = useState(`${value.length}/${maxLength}`);

    const handleOnChange = ({ target }) => {
        setCounter(`${target.value.length}/${maxLength}`);
        onChange(name, target.value);
    }

    const ref = useRef();

    useEffect(() => {
        if( focus ) {
            setTimeout(() => {
                ref.current.focus();
            }, 0)
        }
    }, [focus]);

    return (
        <>
            { label && <label htmlFor="">{ label }</label> }
            { sublabel && <label className={ cssSubtitle }> { sublabel }</label> }
            <div className={ styles.error_container }>
                <textarea ref={ ref }
                    style={ borderError(value.length >= maxLength || (error && value.length === 0)) }
                    name={ name }
                    placeholder={ placeholder }
                    value={ value }
                    cols="30" rows="10" 
                    maxLength={ maxLength }
                    onChange={ handleOnChange }
                >
                </textarea>
                <div className={ styles.error_container_line }>
                    <div>
                        { 
                            value.length === maxLength && 
                            (<p className={ styles.error_message_font }>{ appContext.t(errorLen) }</p>) 
                        }
                        { error && value.length === 0 && (<p className={ styles.error_message_font }>{ error }</p>) }
                    </div>
                    <div className={ cssCounter }>
                        <label className={ `${cssCounter} ${ value.length >= maxLength ? styles.error_message : '' }` } htmlFor="">{ counter }</label>
                    </div>
                </div>
            </div>
        </>
    )
}

TextArea.propTypes = {
    name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string
};