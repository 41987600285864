import { useContext } from 'react';
import { AppContext } from '../../context/AppProvider';
import styles from '../../candidateInfo.module.scss';
import editIcon from '../../../../../assets/icons/orange_pencil.svg';

export const WorkExperienceResume = (
    {
        personalInfo,
        updateView,
        getParsedDate
    }
) => {

    const appContext = useContext(AppContext);

    return (
        <div className={ styles.section }>
            <div className={ styles.section_header}>
                <div className={ styles.section_title }>
                    <div className={ styles.decorator }></div>
                    { appContext.t('candidate.resume.we.title') }
                </div>
                <div className={ styles.button_container }>
                    <div className={ styles.vertical_separator }></div>
                    <button className={ styles.add_info } onClick={() => updateView(1, appContext.t('candidate.resume.we.title'))}>
                        { appContext.t('candidate.resume.edit') }
                        <img src={ editIcon } alt="" />
                    </button>
                </div>
            </div>
            <div className={ styles.cards_wrapper}>
                {
                    personalInfo.previousJobs?.map((jobInfo, index) => {
                        const periodStart = jobInfo.startDate ? getParsedDate(jobInfo.startDate) : appContext.t('noData.unspec');
                        const periodEnd = jobInfo.endDate ? getParsedDate(jobInfo.endDate) : appContext.t('noData.present');
                        const period = periodStart === appContext.t('noData.unspec') && periodEnd === appContext.t('noData.present') ? appContext.t('candidate.resume.courses.notPeriod') : `${periodStart} - ${periodEnd}`;
                        return (
                            <div key={index} className={ styles.info_card }>
                                <div className={ styles.card_title}>
                                    <label>{ jobInfo.position }</label>
                                    <label className={ styles.hidden_mobile }>-</label>
                                    <label>{(jobInfo.employer || appContext.t('noData.notset')) }</label>
                                    <label className={ styles.hidden_mobile }>-</label>
                                    <label>{ appContext.t('candidate.resume.info.sector') }: {(jobInfo.sectorName || appContext.t('noData.notset')) }</label>
                                </div>
                                <p className={ styles.info_date }>{ period }</p>
                                <p className={ styles.info_description}>{ jobInfo.description || appContext.t('candidate.resume.we.noDesc') }</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}