import { useContext } from 'react';
import { AppContext } from '../../context/AppProvider';
import styles from '../../candidateInfo.module.scss';
import editIcon from '../../../../../assets/icons/orange_pencil.svg';
import { softwareCatalogId } from '../../../../core/talent-module/hardcode';

export const SoftwareResume = (
    {
        personalInfo,
        updateView
    }
) => {

    const appContext = useContext(AppContext);

    return (
        <div className={ styles.section }>
            <div className={ styles.section_header}>
                <div className={ styles.section_title }>
                    <div className={ styles.decorator }></div>
                    { appContext.t('candidate.resume.skills.work') }
                </div>
                <div className={ styles.button_container }>
                    <div className={ styles.vertical_separator }></div>
                    <button className={ styles.add_info } onClick={() => updateView(6, appContext.t('candidate.resume.skills.work'))}>
                        { appContext.t('candidate.resume.edit') }
                        <img src={ editIcon } alt="" />
                    </button>
                </div>
            </div>
            <div className={ styles.pills_wrapper }>
                {
                    personalInfo.skills?.filter(s => s.categoryId === softwareCatalogId).map((skillInfo, index) => (
                        <p key={index}>{ `${skillInfo.name} (${ skillInfo.skillLevelPercentage }%)` }</p>
                    ))
                }
            </div>
        </div>
    )
}