import { useReducer } from "react"

import { types } from './types';
import { CandidateContext } from "./CandidateContext";
import { candidateReducer } from "./candidateReducer";
import { newSkill, selectEmtpy } from "../../../core/hardcode";
import { useTranslation } from "react-i18next";
import { objEquals } from "../../../core/helper";

const assigmentEmpty = {
    id: 0,
    client: selectEmtpy,
    area: '',
    leaderArea: selectEmtpy,
    leader: selectEmtpy,
    startDate: '',
    endDate: '',
    hireDate: ''
}

const visibilityEmpty = {
    id: 0,
    visibilityId: 1,
    visibility: selectEmtpy
}

const workExperienceEmpty = {
    id: 0,
    position: '',
    employer: '',
    description: '',
    stillWork: false,
    startDate: null,
    endDate: null,
    fiMes: selectEmtpy,
    fiAnio: selectEmtpy,
    ftMes: selectEmtpy,
    ftAnio: selectEmtpy,
    period: '',
    sectorId: null,
    sectorName: '',
    sector: selectEmtpy
}

const academicEmpty = {
    courseId: 0,
    name: '',
    school: '',
    stillStudy: false,
    endDate: null,
    fIniMes: selectEmtpy,
    fIniAnio: selectEmtpy,
    fTerMes: selectEmtpy,
    fTerAnio: selectEmtpy,
    type: selectEmtpy,
    certificateNumber: null,
    period: ''
}

const courseEmpty = {
    courseId: 0,
    name: '',
    school: '',
    stillStudy: false,
    validity: false,
    endDate: null,
    validityDate: null,
    fiMes: selectEmtpy,
    fiAnio: selectEmtpy,
    ftMes: selectEmtpy,
    ftAnio: selectEmtpy,
    fvMes: selectEmtpy,
    fvAnio: selectEmtpy,
    period: '',
    type: selectEmtpy,
    certificateNumber: ''
}

const languageEmpty = {
    id: 0,
    languageId: 0,
    langLevelId: 1,
    language: selectEmtpy
}

const skillEmpty = {
    id: 0,
    tagId: 0,
    name: '',
    description: '',
    categoryId: 0,
    skillLevelPercentage: 0,
    tag: selectEmtpy
}

const init = () => {
    return {
        personalInfoCache: {},
        assigment: assigmentEmpty,
        assigmentCache: assigmentEmpty,
        assigmentErrors: {},
        visibility: visibilityEmpty,
        visibilityErrors: [],
        workExperience: workExperienceEmpty,
        academic: academicEmpty,
        certification: courseEmpty,
        language: languageEmpty,
        languageErrors: [],
        skill: skillEmpty,
        skillErrors: {},
        competition: newSkill,
        competitionCache : newSkill,
        competitionErrors: {},
        visibilities: [],
        visibilitiesCache: [],
        workExperiences: [],
        workExperiencesCache: [],
        academics: [],
        academicsCache: [],
        certifications: [],
        certificationsCache: [],
        languages: [],
        languagesCache: [],
        skills: [],
        skillsCache: []
    }
}

export const CandidateProvider = ({ children }) => {

    const { t } = useTranslation();

    const [candidateState, dispatch] = useReducer(candidateReducer, {}, init);

    const setPersonalInfoCache = ( personalInfo = {} ) => {
        dispatch({ type: types.setPersonalInfo, payload: personalInfo });
    }

    const setAssigment = ( assigment = {} ) => {
        dispatch({ type: types.setAssigment, payload: assigment });
    }

    const setAssigmentCache = ( assigment = {} ) => {
        dispatch({ type: types.setAssigmentCache, payload: assigment });
    }

    const setAssigmentErrors = assigmentErrors => {
        dispatch({
            type: types.setAssigmentErrors,
            payload: assigmentErrors
        })
    }

    const cleanVisibility = ( ) => {
        dispatch({
            type: types.setVisibility,
            payload: visibilityEmpty
        });
    }

    const setVisibilityErrors = visibilityErrors => {
        dispatch({
            type: types.setVisibilitiesErrors,
            payload: visibilityErrors
        })
    }

    const setWorkExperience = ( workExperience = {} ) => {
        const endDateStr = workExperience.stillWork ? t('noData.present') : `${workExperience.ftMes.description} ${workExperience.ftAnio.description}`;
        const period = `${workExperience.fiMes.description} ${workExperience.fiAnio.description} - ${endDateStr}`;
        dispatch({ 
            type: types.setWorkExperience, 
            payload: {
                ...workExperience,
                period: period
            }
        });
    }

    const cleanWorkExperience = ( ) => {
        dispatch({ 
            type: types.setWorkExperience, 
            payload: workExperienceEmpty
        });
    }

    const setAcademic = ( academic = {} ) => {
        const startDateStr = objEquals(academic.fIniMes,selectEmtpy) && objEquals(academic.fIniAnio,selectEmtpy) ? t('noData.unspec') : `${academic.fIniMes.description} ${academic.fIniAnio.description}`;
        const endDateStr = academic.stillStudy ? t('noData.inProg') : `${academic.fTerMes.description} ${academic.fTerAnio.description}`;
        const periodStr = startDateStr === t('noData.unspec') && endDateStr === t('noData.inProg') ? t('candidate.resume.courses.notPeriod') : `${startDateStr} - ${endDateStr}`;
        dispatch({
            type: types.setAcademic, 
            payload: {
                ...academic,
                period: periodStr
            }
        });
    }

    const cleanAcademic = ( ) => {
        dispatch({ 
            type: types.setAcademic, 
            payload: academicEmpty
        });
    }

    const setCertification = ( certification = {} ) => {
        const periodStart = objEquals(certification.fiMes,selectEmtpy) && objEquals(certification.fiAnio,selectEmtpy) ? t('noData.notset') : `${certification.fiMes.description} ${certification.fiAnio.description}`;
        const periodEnd = objEquals(certification.ftMes,selectEmtpy) && objEquals(certification.ftAnio,selectEmtpy) ? t('noData.inProg') : `${certification.ftMes.description} ${certification.ftAnio.description}`;
        const periodStr = periodStart === t('noData.notset') && periodEnd === t('noData.inProg') ? t('candidate.resume.courses.notPeriod') : `${periodStart === t('noData.notset') ? '' : periodStart + ' - ' }${periodEnd}`;
        dispatch({ 
            type: types.setCertification, 
            payload: {
                ...certification,
                startDateParsed: periodStart,
                endDateParsed: periodEnd,
                period: periodStr
            }
        });
    }

    const cleanCertification = ( ) => {
        dispatch({ 
            type: types.setCertification, 
            payload: courseEmpty
        });
    }

    const cleanLanguage = ( ) => {
        dispatch({
            type: types.setLanguage,
            payload: languageEmpty
        });
    }

    const setLanguageErrors = languageErrors => {
        dispatch({
            type: types.setLanguageErrors,
            payload: languageErrors
        })
    }

    const setSkill = ( skill = {} ) => {
        dispatch({
            type: types.setSkill,
            payload: skill
        });
    }

    const cleanSkill = categoryId => {
        dispatch({
            type: types.setSkill,
            payload: { ...skillEmpty, 'categoryId': categoryId }
        });
    }

    const setCompetition = competition => {
        dispatch({
            type: types.setCompetition,
            payload: competition
        });
    }

    const setCompetitionCache = competition => {
        dispatch({
            type: types.setCompetitionCache,
            payload: competition
        });
    }

    const setCompetitionErrors = competitionErrors => {
        dispatch({
            type: types.setCompetitionErrors,
            payload: competitionErrors
        });
    }

    const setSkillErrors = skillErrors => {
        dispatch({
            type: types.setSkillErrors,
            payload: skillErrors
        })
    }

    const setVisibilities = ( visibilities = [] ) => {
        dispatch({ type: types.setVisibilities, payload: visibilities });
    }

    const setVisibilitiesCache = ( visibilities = [] ) => {
        dispatch({ type: types.setVisibilitiesCache, payload: visibilities });
    }

    const setWorkExperiences = ( workExperiences = [] ) => {
        dispatch({ type: types.setWorkExperiences, payload: workExperiences });
    }

    const setWorkExperiencesCache = ( workExperiences = [] ) => {
        dispatch({ type: types.setWorkExperiencesCache, payload: workExperiences });
    }

    const setAcademics = ( academics = [] ) => {
        dispatch({ type: types.setAcademics, payload: academics });
    }

    const setAcademicsCache = ( academics = [] ) => {
        dispatch({ type: types.setAcademicsCache, payload: academics });
    }

    const setCertifications = ( certifications = [] ) => {
        dispatch({ type: types.setCertifications, payload: certifications });
    }

    const setCertificationsCache = ( certifications = [] ) => {
        dispatch({ type: types.setCertificationsCache, payload: certifications });
    }

    const setLanguages = ( languages = [] ) => {
        dispatch({ type: types.setLanguages, payload: languages });
    }

    const setLanguagesCache = ( languages = [] ) => {
        dispatch({ type: types.setLanguagesCache, payload: languages });
    }

    const setSkills = ( skills = [] ) => {
        dispatch({ type: types.setSkills, payload: skills });
    }

    const setSkillsCache = ( skillsCache = [] ) => {
        dispatch({ type: types.setSkillsCache, payload: skillsCache });
    }

    return (
        <CandidateContext.Provider value={{
            ...candidateState,
            setPersonalInfoCache,
            setAssigment,
            setAssigmentCache,
            setAssigmentErrors,
            cleanVisibility,
            setWorkExperience,
            cleanWorkExperience,
            setAcademic,
            cleanAcademic,
            setCertification,
            cleanCertification,
            cleanLanguage,
            setSkill,
            cleanSkill,
            setCompetition,
            setCompetitionCache,
            setCompetitionErrors,
            setVisibilities,
            setVisibilitiesCache,
            setVisibilityErrors,
            setWorkExperiences,
            setWorkExperiencesCache,
            setAcademics,
            setAcademicsCache,
            setCertifications,
            setCertificationsCache,
            setLanguages,
            setLanguagesCache,
            setLanguageErrors,
            setSkills,
            setSkillErrors,
            setSkillsCache
        }}>
            { children }
        </CandidateContext.Provider>
    )

}