import styles from '../../talentInfo.module.scss';
import talentListNotFound from '../../../../../assets/img/talent_list_not_found.png';
import talentListNotMatch from '../../../../../assets/img/talent_list_not_match.png';
import imageError from '../../../../../assets/img/image_error.png';

export const InfoMessageType = {
    notFound: '[Message]: Not Found',
    notMatch: '[Message]: Not Match',
    error: '[Message]: Error'
}

export const InfoMessage = ({
    title,
    subtitles,
    type,
    cssParagraph,
    shadow
}) => {

    const chooseImage = () => {
        let imgSrc = ''
        switch (type) {
            case InfoMessageType.notFound:
                imgSrc = talentListNotFound;
                break;
            case InfoMessageType.notMatch:
                imgSrc = talentListNotMatch;
                break;
            case InfoMessageType.error:
                imgSrc = imageError;
                break;
            default:
                break;
        }
        return imgSrc;
    }

    return (
        <div className={ `${styles.not_found} ${ shadow ? styles.box_shadow : undefined }` }>
            <label>{ title }</label>
            {
                subtitles.map( (subtitle, index) => (
                    <p className={ `${styles.pharagrap} ${cssParagraph}` } key={ index }>{ subtitle }</p>
                ))
            }
            { type && <img src={ chooseImage() } alt='' /> }
        </div>
    )
}
