import Axios from 'axios';

const apiUrl = process.env.REACT_APP_CATALOG_API;

export const getCatalog = (catalogId, page ,size, sort) => Axios.get(`${apiUrl}${catalogId}/catalogItem/list?page=${page}&size=${size}${sort}`);

export const getSkillsCatalog = (page ,size) => Axios.get(`${apiUrl}candidates/skills/?page=${page}&size=${size}&sort=name&direction=ASC`,{'Accept-Language': 'es-MX'});

//export const getLanguageCatalog = () => Axios.get(`${apiUrl}language/`,{'Accept-Language': 'es-MX'});
 
export const getLangLevelCatalog = () => Axios.get(`${apiUrl}language/level`,{'Accept-Language': 'es-MX'});

export const getClientCatalog = sort => Axios.get(`${apiUrl}client/?sortBy=${ sort ? sort : 'name' }`,{'Accept-Language': 'es-MX'});

export const getProfiles = (filter, page=0, size=10, clientFilter, visibilityFilter) => {
    const filterParam = filter ? `&name=${filter}` : '';
    let clientParam = '';
    if( clientFilter ) {
        clientFilter.forEach( c => clientParam += `&clientId=${c.id}` )
    }
    const publicParam = visibilityFilter.length === 1 ? `&isPublic=${ visibilityFilter.find( v => v.id === 1) ? 'true' : 'false' }` : '';
    const sorter = '&sortBy=name&sortDir=ASC';
    return Axios.get(`${apiUrl}skillsprofile/all?paginated=true&page=${page}&size=${size}${sorter}${filterParam}${clientParam}${publicParam}`);
}

export const getProfile = id => Axios.get(`${apiUrl}skillsprofile/${id}`);

export const persistProfile = profile => Axios.post(`${apiUrl}skillsprofile/save`, profile);