import { Suspense} from 'react';
import { Route,Routes } from 'react-router-dom';
import { AppProvider } from '../app/pages/candidates/context/AppProvider';
import { CandidateProvider } from '../app/pages/candidates/context';
import { CatalogProvider } from '../app/pages/catalogs/context';
import LogIn from '../app/pages/login/login';
import PasswordRecovery from '../app/pages/password_recovery/password_recovery';
import { Home } from '../app/pages/home/Home';
import { PdfPreviewer } from '../app/components/pfdpreviewew/PdfPreviewer';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback, logError } from '../app/core/ErrorHandler';

const AppRouter = () => {

    return (
        <AppProvider>
            <CatalogProvider>
                <CandidateProvider>
                    <Suspense fallback="loading">
                        <ErrorBoundary FallbackComponent={ ErrorFallback } onError={logError}>
                            <Routes>
                                <Route path="/" element={ <LogIn /> } />
                                <Route path="login" element={ <LogIn /> } />
                                <Route path="password-recovery" element={ <PasswordRecovery /> } />
                                <Route path='home' element={ <Home /> } />
                                <Route path='pdf-preview/:talentId' element={ <PdfPreviewer /> } />
                            </Routes>
                        </ErrorBoundary>
                    </Suspense>
                </CandidateProvider>
            </CatalogProvider>
        </AppProvider>
    )
}
export default AppRouter;