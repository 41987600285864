import { useState, useContext } from 'react';
import styles from './header.module.scss';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow_down.svg';
import logoutIcon from '../../../assets/icons/logout.svg';
import menuMobileIcon from '../../../assets/icons/icon_menu_mobile.svg';
import menuMobileIconClose from '../../../assets/icons/icon_menu_mobile_close.svg';
import { AppContext } from '../../pages/candidates/context/AppProvider';

const Header = ({
    hideMenu = false,
    hideUser = false
}) => {

    const appContext = useContext(AppContext);
    const [menuFlag, setMenuFlag] = useState(false)

    const logOutActions = () => {
        localStorage.removeItem('userInfo');
        appContext.setUserData(null)
        appContext.setModalFlag(false)
        appContext.cleanFilter();
        appContext.navigate("/login")
    }

    const onClickNavigation = (objNav, index) => {
        if( objNav.component ) {
            appContext.setMenuOptSelected(objNav.label);
            appContext.setMenuNavigation([{ label: objNav.label, component: objNav.component }]);
            appContext.setHomeContent(objNav.component);
        } else if ( objNav.onEvent ) {
            appContext.setMenuNavigation(appContext.menuNavigation.slice(0, index+1));
            objNav.onEvent(objNav.arg);
        } else {
            console.log('There is not click event')
        }
    }

    const renderMenu = () => (
        <div className={ styles.header }>
            <div className={ `${styles.wrapper} ${appContext.menuNavigation && appContext.menuNavigation.length > 1 ? '' : styles.complete}` }>
                <div className={ styles.header_logo}>
                    <a href="https://www.mystratis.com" target="_blank">
                        <img src="https://www.mystratis.com/wp-content/themes/stratis/images/logo.svg" alt="Stratis" />
                    </a>
                    <div className={ styles.decorator }></div>
                    <label>Back Office</label>
                </div>
                { !hideUser && (appContext.userData && appContext.userData.userProfile ) && ( <div className={ styles.logout_wrapper }>
                    <button onClick={ () => setMenuFlag(!menuFlag)}>
                        <p>{ appContext.t('header.wellcome') }, <span>{ appContext.userData.userProfile.firstName }</span></p>
                        <ArrowIcon />
                    </button>
                </div> ) }
            </div>
            <div className={ styles.navigation }>
                {
                    !hideMenu && appContext.menuNavigation && appContext.menuNavigation.length > 1 && appContext.menuNavigation.map((o, index) => {
                        return index === appContext.menuNavigation.length-1 ? (
                            <label key={ `mnlbl${index}` } className={ styles.lastone }>{ o.label }</label>
                        ) : (
                            <div key={ `mndiv${index}` } className={ styles.label }>
                                <label key={ `mnlbl${index}` } onClick={ () => onClickNavigation(o, index) }>{ o.label }</label>
                                <ArrowIcon key={ `mnicon${index}` } />
                            </div>
                        )
                    }) 
                }
            </div>
        </div>
    )

    const renderMenuMobile = () => (
        <div className={ styles.header }>
            <div className={ styles.wrapper }>
                <a href="https://www.mystratis.com" target="_blank">
                    <img src="https://www.mystratis.com/wp-content/themes/stratis/images/logo.svg" alt="Stratis" />
                </a>
                { !hideMenu && appContext.userData && appContext.userData.userProfile && <img src={ menuFlag ? menuMobileIconClose : menuMobileIcon } alt="" onClick={ () => setMenuFlag(!menuFlag) } /> }
            </div>
            {
                !hideMenu && appContext.userData && appContext.userData.userProfile && menuFlag &&
                    <div className={ styles.menu_wrapper_mobile }>
                        <p onClick={ logOutActions }>{ appContext.t('header.labelLogout') }</p>
                    </div>
            }
        </div>
    )

    return (
        <div>
            <div className={ styles.header_container }>
                { appContext.isDesktopOrLaptop ? renderMenu() : renderMenuMobile() }
            </div>
            { appContext.isDesktopOrLaptop && 
                <div className={ `${styles.menu_wrapper} ${ menuFlag ? styles.active : ''}` }>
                    <button onClick={ logOutActions } >
                        <img src={ logoutIcon } alt="" />
                        <p>{ appContext.t('header.labelLogout') }</p>
                    </button>
                </div>
            }
        </div>
    )
}

export default Header;